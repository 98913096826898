import React, { Component } from "react";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

import {
  Button,
  Box,
  IconButton,
  Menu,
  Divider,
  MenuItem,
  Typography,
  Switch,
  Container,
  Tooltip
} from "@mui/material";
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Theme, styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { colors } from "../../blocks/utilities/src/Colors";
import { BuyerSidebarDataFirst, BuyerSidebarDataSecond, BuyerSidebarDataThird, SupplierSidebarDataFirst, SupplierSidebarDataSecond, SupplierSidebarDataThird } from "./NavigationSidebarData";
import { logoutIcon } from "../../blocks/navigationmenu/src/assets";
import { AccountAttributes } from "../../blocks/utilities/src/typeInterface";
import { toast } from "react-toastify";
const logo = require("../src/icons-nav/logo.png");

const hdThreedot = require('../src/icons-nav/header-threedot.svg');
const hdSearch = require('../src/icons-nav/header-search-icon.svg');
const hdAi = require('../src/icons-nav/header-ai.svg');
const hdVoiceRc = require('../src/icons-nav/header-voice-record.svg');
const hdChat = require('../src/icons-nav/header-chat.svg');
const hdNotify = require('../src/icons-nav/header-notify.svg');
const hdShoping = require('../src/icons-nav/header-shoping.svg');
const hdShoping2 = require('../src/icons-nav/yellow_cart.svg');
const SquareLockIcon = require('./icons-nav/lock-square-rounded.svg');
const LockIcon = require('./icons-nav/lock_icon.svg')
const LockIconYellow = require('./icons-nav/lock_icon_yellow.svg')

interface Props {
  navigation?: any,
  configJSON: any,
  token: string,
  activePath: string,
  onHandleLogout: any
  onHandleBuyerSupplier: any,
  onOpenSearchModal: () => void,
  isSupplier: boolean,
  userInfo: Partial<AccountAttributes>
}
interface S {
  anchorEl: null | HTMLElement;
}
export interface MenuItem1 {
  id: number;
  name: string;
  path: string;
}
class NavbarBeforeLogin extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  };

  handleMenuNavigation = (path: string, dynamicPath?: string) => {
    if (path) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), path);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), dynamicPath);
      runEngine.sendMessage(message.id, message);
      this.handleMenuClose();
    }
  };

  render() {
    const { anchorEl } = this.state;
    const { configJSON, token, activePath, isSupplier, onHandleBuyerSupplier } = this.props;

    const unlockComponent = () => {
      return (
        <div className="unlockContainer"></div>
      )
    };

    const onClickMenuItem = (item: { title: {} | null | undefined; link: string; commingSoon: any; isLocked: any; secondPath: any; icon: string | undefined; name: string; }) => {
      if(item.commingSoon) {
        this.handleMenuClose();
        toast.info(configJSON.comingSoonToast);
      } else if(item.isLocked) {
        return false
      } else {
        this.handleMenuNavigation(item.link, item.secondPath)
      }
    };

    const commonMenuItem = (item: { title: {} | null | undefined; link: string; commingSoon: any; isLocked: any; secondPath: any; icon: string | undefined; name: string; }) => {
      return (
        item.commingSoon ?
          <CustomTooltip arrow title={configJSON.comingSoon} placement="right">
            
            <MobileMenuItemWrapper key={item.name} onClick={() => onClickMenuItem(item)}>
              <MobileMenuItem className={`${(item.commingSoon || item.isLocked) && "lockedMenuOuter"}`} selected={!!(item.link && activePath.includes(item.link))} key={item.name}>
                <div className={`${(item.commingSoon || item.isLocked) && "opacity"} lockedMenuInner`}>
                  <div className={`sidebarIcon ${(item.link && activePath.includes(item.link)) && "selected"}`}><img src={item.icon} /></div>
                  <div className={"title" + item.name} >{item.title}</div>
                </div>
                {item.isLocked && unlockComponent()}
              </MobileMenuItem>
            </MobileMenuItemWrapper>
          </CustomTooltip> :
          <MobileMenuItemWrapper key={item.name} onClick={() => onClickMenuItem(item)}>
          <MobileMenuItem className={`${(item.commingSoon || item.isLocked) && "lockedMenuOuter"}`} selected={!!(item.link && activePath.includes(item.link))} key={item.name}>
            <div className={`${(item.commingSoon || item.isLocked) && "opacity"} lockedMenuInner`}>
              <div className={`sidebarIcon ${(item.link && activePath.includes(item.link)) && "selected"}`}><img src={item.icon} /></div>
              <div className={"title" + item.name} >{item.title}</div>
            </div>
            {item.isLocked && unlockComponent()}
          </MobileMenuItem>
          </MobileMenuItemWrapper>
      )
    };

    const renderTabMenuBeforeLogin = () => {
      return (
        <>
          {configJSON.beforeLoginMenus.map((item: MenuItem1) => (
            <MobileMenuItemWrapper key={item.id} onClick={() => this.handleMenuNavigation(item.path)}>
              <MobileMenuItem selected={item.path === activePath} key={item.id}>
                {item.name}
              </MobileMenuItem>
            </MobileMenuItemWrapper>
          ))}
          <Divider sx={{ margin: "2px 0 8px", backgroundColor: "#E2E8F0" }} />
          <MobileMenuItemWrapper>
            <MobileMenuItem selected={configJSON.contactRoute === activePath} onClick={() => this.handleMenuNavigation(configJSON.contactRoute)}>
              {configJSON.contactText}
            </MobileMenuItem>
          </MobileMenuItemWrapper>
        </>
      )
    };

    const renderTabMenuAfterLogin = () => {
      const firstData = isSupplier ? SupplierSidebarDataFirst : BuyerSidebarDataFirst;
      const secondData = isSupplier ? SupplierSidebarDataSecond : BuyerSidebarDataSecond;
      const thirdData = isSupplier ? SupplierSidebarDataThird : BuyerSidebarDataThird;
      return (
        <>
          {firstData.map((item) => (
            commonMenuItem(item)
          ))}
          <Divider sx={{ margin: "2px 0", backgroundColor: "#E2E8F0" }} />
          {secondData.map((item) => (
            commonMenuItem(item)
          ))}
          <Divider sx={{ margin: "2px 0", backgroundColor: "#E2E8F0" }} />
          {thirdData.map((item) => (
            commonMenuItem(item)
          ))}
          <MobileMenuItemWrapper >
            <MobileMenuItem onClick={() => this.props.onHandleLogout()}>
              <div className="sidebarIcon" ><img src={logoutIcon} /></div>
              Log out
            </MobileMenuItem>
          </MobileMenuItemWrapper>
          <Divider sx={{ margin: "2px 0", backgroundColor: "#E2E8F0" }} />
          <MobileMenuItemWrapper>
            <Box className="sb-userinfo-b">
              <Typography className={`${isSupplier ? "sb-userinfo-b-supplier" : "sb-userinfo-b-buyer"}`}>{configJSON.buyerText}</Typography>
              <AntSwitch
                checked={isSupplier}
                onChange={(event) => onHandleBuyerSupplier(event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography className={`${!isSupplier ? "sb-userinfo-b-supplier" : "sb-userinfo-b-buyer"}`}>{configJSON.supplierText}</Typography>
            </Box>
          </MobileMenuItemWrapper>
        </>
      )
    };

    const renderHeaderBeforeLogin = () => {
      return (
        <Box sx={styles1.allMenuOuter}>
          <Box sx={styles1.menuContainer}>
            <Box sx={styles1.navbar1}>
              {
                configJSON.beforeLoginMenus.map((menu: MenuItem1) => <StyledMenuItem selected={menu.path === activePath} key={menu.id} onClick={() => this.handleMenuNavigation(menu.path)} data-test-id="menuItem">{menu.name}</StyledMenuItem>)
              }
            </Box>
            <Box sx={styles1.navbar2}>
              <StyledMenuItem selected={configJSON.contactRoute === activePath} data-test-id="contactus" onClick={() => this.handleMenuNavigation(configJSON.contactRoute)}>{configJSON.contactText}</StyledMenuItem>
            </Box>
            <Box sx={styles1.navbar3}>
              <LoginButton data-test-id="loginBtn" onClick={() => this.handleMenuNavigation(configJSON.loginRoute)}>
                {configJSON.loginText}
              </LoginButton>
              <SignupButton data-test-id="signupBtn" onClick={() => this.handleMenuNavigation(configJSON.signupRoute)}>
                {configJSON.signupText}
              </SignupButton>
            </Box>
          </Box>
        </Box>
      )
    };

    const renderHeaderAfterLogin = () => {
      return (
        <LoggedHeader>
          <img src={logo} className="logo_image" />
          <Box className="centerContainer">
            <div className="threedotOuter">
              <div className="threedotInner leftInner">
                <span><img src={hdThreedot} /></span>
              </div>
            </div>
            <div className="searchContainer">
              <span className="searchIcon"><img src={hdSearch} onClick={this.props.onOpenSearchModal} /></span>
              {/* <span className="searchInput"><input placeholder="SEARCH MATERIAL BY NAME, CATEGORY, LOCATION" /></span> */}
              <span className="searchText" onClick={this.props.onOpenSearchModal}>SEARCH MATERIAL BY NAME, CATEGORY, LOCATION</span>
              <div className="aiContainer">
                <span className="aiIcon"><img src={hdAi} /></span>
                <span className="voiceIcon"><img src={hdVoiceRc} /></span>
              </div>
            </div>
            <div className="threedotOuter">
              <div className="threedotInner rightInner">
                <span><img src={hdThreedot} /></span>
              </div>
            </div>
          </Box>
          <div className="rightIconContainer">
            <span className="chatIcon"><img src={hdChat} /></span>
            <span className="notifyIcon"><img src={hdNotify} /></span>
            <span className="shopingIcon"><img src={hdShoping} /></span>
          </div>
        </LoggedHeader>
      )
    };


    return (
      <OuterMainContainer>
        <Box sx={token ? styles1.afterLoginHeaderContainer : styles1.headerContainer}>
          <LogoContainer className={token && "hideLogo"}>
            <MenuIconBtn onClick={this.handleMenuOpen}>
              <MenuIcon />
            </MenuIconBtn>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              slotProps={{
                paper: {
                  style: {
                    padding: "0px 5px",
                    width: "242px",
                    boxSizing: "border-box"
                  },
                },
              }}
              className="menuListContainer"
            >
              {token ? renderTabMenuAfterLogin() : renderTabMenuBeforeLogin()}
            </Menu>

            <img src={logo} className="logo_img" />
          </LogoContainer>

          {token &&
            <RightSideContainer>
              <Box className="innerContainer">
                <div className="emptyContainer">
                  <div className="innerEmptyContainer">

                  </div>
                </div>
                <Box className="iconContainer">
                  <img className="search-icon" src={hdSearch} onClick={this.props.onOpenSearchModal} />
                  <img className="shoping-icon" src={hdShoping2} />
                </Box>
              </Box>
            </RightSideContainer>
          }

          {token ? renderHeaderAfterLogin() : renderHeaderBeforeLogin()}
        </Box>
      </OuterMainContainer>
    );
  }
}

const LoggedHeader = styled(Container)({
  marginTop: "-21px",
  padding: "0px !important",
  maxWidth: "100% !important",
  width: "100% !important",
  height: "89px",
  backgroundColor: "#FFFFFF",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  '@media (max-width: 1024px)': {
    display: "none",
  },
  "& .logo_image": {
    height: "13px",
    '@media (max-width: 1100px)': {
      height: "11px",
    },
    '@media (max-width: 900px)': {
      height: "9px",
      width: "137px",
    },
    '@media (max-width: 600px)': {
      height: "6.72px",
      width: "103px",
    },
  },

  "& .centerContainer": {
    height: "100%",
    display: "flex",
  },

  "& .threedotOuter": {
    height: "100%",
    width: "100%",
    backgroundColor: colors().ultraDarkGreen,
  },
  "& .threedotInner": {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    backgroundColor: "#fff",
    "&.leftInner": {
      borderRadius: "0 45px 0 0",
      paddingRight: "40px",
      '@media (max-width: 1340px)': {
        paddingRight: "30px",
      },
      '@media (max-width: 1300px)': {
        paddingRight: "20px",
      },
      '@media (max-width: 1200px)': {
        paddingRight: "5px",
      },
      '@media (max-width: 1100px)': {
        paddingRight: "2px",
      },
    },
    "&.rightInner": {
      borderRadius: "45px 0 0 0",
      paddingLeft: "40px",
      '@media (max-width: 1340px)': {
        paddingLeft: "30px",
      },
      '@media (max-width: 1300px)': {
        paddingLeft: "20px",
      },
      '@media (max-width: 1200px)': {
        paddingLeft: "5px",
      },
      '@media (max-width: 1100px)': {
        paddingLeft: "2px",
      },
    },
  },

  "& .searchContainer": {
    height: "100%",
    maxWidth: "599px",
    padding: "0px 53px 0px 47px",
    display: "flex",
    alignItems: "center",
    backgroundColor: colors().ultraDarkGreen,
    borderRadius: "0 0 50px 50px",
    gap: "54px",
    paddingLeft: "40px",
    '@media (max-width: 1340px)': {
      padding: "0px 48px 0px 42px",
      gap: "44px",
    },
    '@media (max-width: 1300px)': {
      padding: "0px 43px 0px 37px",
      gap: "34px",
    },
    '@media (max-width: 1200px)': {
      padding: "0px 38px 0px 32px",
      gap: "20px",
    },
    '@media (max-width: 1100px)': {
      padding: "0px 28px 0px 22px",
      gap: "20px",
    },
  },
  "& .searchIcon": {
    height: "32.63px",
    width: "32.63px",
  },
  // "& .searchInput": {
  //   "& input": {
  //     backgroundColor: "transparent",
  //     width: "296px",
  //     border: "none",
  //     fontSize: "12px",
  //     fontWeight: 420,
  //     lineHeight: "17.16px",
  //     color: "red",
  //     '&::placeholder': {
  //       color: "#A8A29E",
  //     },
  //   }
  // },
  "& .searchText": {
    backgroundColor: "transparent",
    width: "294px",
    border: "none",
    fontSize: "12px",
    fontWeight: 420,
    lineHeight: "17.16px",
    color: "#A8A29E",
  },
  "& .aiContainer": {
    display: "flex",
    gap: "11px"
  },
  "& .aiIcon": {
    height: "24px",
    width: "29px",
    cursor: "pointer"
  },
  "& .voiceIcon": {
    height: "24px",
    width: "24px",
    cursor: "pointer"
  },
  "& .rightIconContainer": {
    display: "flex",
    alignItems: "center",
    gap: "41px",

    '@media (max-width: 1340px)': {
      gap: "31px",
    },
    '@media (max-width: 1300px)': {
      gap: "21px",
    },
    '@media (max-width: 1200px)': {
      gap: "15px",
    },
    '@media (max-width: 1100px)': {
      gap: "8px",
    },
  },
  "& .chatIcon": {
    cursor: "pointer"
  },
  "& .notifyIcon": {
    cursor: "pointer"
  },
  "& .shopingIcon": {
    cursor: "pointer",
    marginTop: "-15px",
  },
}
);

const OuterMainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  borderTop: `8px solid ${colors().ultraDarkGreen}`,
  '@media (max-width: 1024px)': {
    border: "none",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }: { theme: Theme }) => ({
  borderRadius: '10px',
  textTransform: 'none',
  fontSize: '16px',
  cursor: 'pointer',
  flexGrow: '1',
  flexShrink: '1',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  height: "100%",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.primary.contrastText,
  padding: '6px 12px',
  fontWeight: 420,

  '@media (max-width: 1465px)': {
    padding: '6px 10px',
  },
  '@media (max-width: 1433px)': {
    padding: '6px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '7px 27px',
  },

  '&.MuiButtonBase-root.MuiMenuItem-root': {
    color: theme.palette.text.primary,
  },

  '&.MuiButtonBase-root.MuiMenuItem-root:hover': {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.contrastText,
    fontWeight: 450,
  },
  "&.MuiButtonBase-root.Mui-selected": {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.secondary.main,
    fontWeight: 450,
  }
}));

const LoginButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  color: colors().blackText,
  borderRadius: "10px",
  textTransform: "none",
  fontSize: "16px",
  pointer: "cursor",
  height: "100%",
  padding: "10px 20px",
  minWidth: 80,
  fontWeight: 420,

  "&.MuiButtonBase-root.MuiButton-root": {
    color: colors().blackText,
  },
  "&.MuiButtonBase-root.MuiButton-root:hover": {
    color: colors().darkGold,
    fontWeight: 450,
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: "12px",
    padding: "10px",
    minWidth: "auto",
  },
}));
const SignupButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  color: colors().blackText,
  borderRadius: "10px",
  textTransform: "none",
  fontSize: "16px",
  pointer: "cursor",
  height: "inherit",
  fontWeight: 420,
  "&.MuiButtonBase-root.MuiButton-root": {
    color: colors().blackText,
    background: `linear-gradient(99.09deg, #D8C487 2.64%, ${colors().darkGold} 100%)`,
    whiteSpace: "nowrap",
    borderRadius: "0px",
  },
  "&.MuiButtonBase-root.MuiButton-root:hover": {
    color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: "12px",
    padding: "0",
    margin: "0",
  },
}));
const styles1 = {
  mainContainer: {
    height: "100vh",
    flexDirection: "column" as const,
    justifyContent: "space-between",

    display: "flex",

  },
  headerContainer: (theme: Theme) => ({
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    color: colors().blackText,

    fontSize: "16px",
    margin: "21px 30px 14px",
    flexWrap: "wrap" as const,

    maxWidth: "calc(1600px - 60px)",
    width: "100%",
    '@media (max-width: 1024px)': {
      margin: "13px 24px 10px",
    },
    [theme.breakpoints.down('md')]: {
      margin: "13px 24px 10px",
    },
    [theme.breakpoints.down('sm')]: {
      margin: "10px 10px 10px 24px",
    },

    "&.afterLoginHeaderContainer": {

    }
  }),
  afterLoginHeaderContainer: (theme: Theme) => ({
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    color: colors().blackText,

    fontSize: "16px",
    margin: "21px 52px 14px 69px",
    flexWrap: "wrap" as const,

    // maxWidth: "calc(1600px - 60px)",
    width: "100%",
    '@media (max-width: 1024px)': {
      margin: "13px 24px 10px",
    },
    [theme.breakpoints.down('md')]: {
      margin: "13px 24px 10px",
    },
    [theme.breakpoints.down('sm')]: {
      margin: "10px 10px 10px 24px",
    },
  }),
  menuContainer: (theme: Theme) => ({
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
  }),
  allMenuOuter: {
    display: "flex",
    gap: "20px",
    flexWrap: "wrap" as const,
  },
  navbar1: (theme: Theme) => ({
    height: "35px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "20px",
    flexWrap: 'wrap',
    border: "1px solid #D8C487",
    borderImageSource: "linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%)",
    borderImageSlice: 1,
    opacity: "0px",
    overflow: "hidden",
    padding: "2px",
    '@media (max-width: 1600px)': {
      gap: "5px",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "0",
    },
    '@media (max-width: 1024px)': {
      display: "none",
    },
  }),
  navbar2: (theme: Theme) => ({
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #D8C487",
    borderImageSource: "linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%)",
    borderImageSlice: 1,
    marginTop: "20px",
    marginRight: "20px",
    padding: "2px",
    gap: "10px",
    opacity: "0px",
    [theme.breakpoints.down('md')]: {
      marginTop: "0",
    },
    '@media (max-width: 1369px)': {
      marginRight: "0px",
    },
    '@media (max-width: 1024px)': {
      display: "none",
    },
  }),
  navbar3: (theme: Theme) => ({
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #D8C487",
    borderImageSource: "linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%)",
    borderImageSlice: 1,
    marginTop: "20px",
    opacity: "0px",
    padding: "2px",

    [theme.breakpoints.down('md')]: {
      marginTop: "0",
      height: "28px",
      padding: "2px"
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: "0",
      height: "24px",
      padding: "2px"
    },
  }),
};

const RightSideContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "37.75px",
  alignItems: "center",
  backgroundColor: "red",
  marginTop: "-13px",
  marginRight: "-23px",
  minHeight: "65px",
  '@media (min-width: 1025px)': {
    display: "none",
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: "-10px",
    marginRight: "-10px",
    minHeight: "49px",
  },

  "& .innerContainer": {
    display: "flex",
    height: "100%",
    backgroundColor: colors().white,
    "& .iconContainer": {
      display: "flex",
      gap: "37.75px",
      alignItems: "center",
      paddingLeft: "31.36px",
      height: "100%",
      backgroundColor: colors().ultraDarkGreen,
      borderRadius: "0 0 0 32.6px",
      [theme.breakpoints.down('sm')]: {
        gap: "28.3px",
        borderRadius: "0 0 0 40px",
      },
    },
    "& .emptyContainer": {
      width: "30px",
      height: "100%",
      backgroundColor: colors().ultraDarkGreen,
      "& .innerEmptyContainer": {
        width: "100%",
        height: "100%",
        backgroundColor: colors().white,
        borderRadius: "0 25px 0 0",
        [theme.breakpoints.down('sm')]: {
          borderRadius: "0 18px 0 0",
        },
      },
    },
  },

  "& .search-icon": {
    cursor: "pointer",
    height: "23.63px",
    width: "23.63px",
    [theme.breakpoints.down('sm')]: {
      height: "17.72px",
      width: "17.72px",
    },
  },
  "& .shoping-icon": {
    cursor: "pointer",
    height: "36.9px",
    width: "31.63px",
    marginTop: "-15px",
    marginRight: "18px",
    [theme.breakpoints.down('sm')]: {
      height: "27.66",
      width: "23.71px",
    },
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: "20px",
  marginRight: "20px",
  "&.hideLogo": {
    '@media (min-width: 1025px)': {
      display: "none",
    },
  },
  "& .menuListContainer": {
    display: "none",
    '@media (max-width: 1024px)': {
      display: "block",
    },
  },
  [theme.breakpoints.down('md')]: {
    marginTop: "0",
    height: "30px"
  },
  "& .logo_img": {
    height: "13px",
    [theme.breakpoints.between('sm', 'md')]: {
      height: "9px",
      width: "137px",
    },
    [theme.breakpoints.down('sm')]: {
      height: "6.72px",
      width: "103px",
    },
  },
}));

const MenuIconBtn = styled(IconButton)(({ theme }) => ({
  display: 'none',
  marginRight: "16px",
  padding: "0",
  '@media (max-width: 1024px)': {
    display: 'inline-flex',
  },
}));

const MobileMenuItem = styled(MenuItem)(({ theme }) => ({
  justifyContent: "start",
  width: "100%",
  borderRadius: "0px",
  fontSize: "14px",
  fontWeight: 390,
  lineHeight: "14px",
  wordWrap: 'break-word',
  padding: "8px 13px 8px 16px",
  "&.MuiButtonBase-root.MuiMenuItem-root:hover": {
    color: colors().darkGold,
    background: colors().lightGold,
    "& img": {
      filter: 'brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(409%) hue-rotate(1deg) brightness(96%) contrast(101%)'
    },
  },
  "&.MuiButtonBase-root.Mui-selected": {
    color: colors().darkGold,
    background: colors().lightGold,
    fontWeight: 420,
  },
  "& .sidebarIcon": {
    height: "24px",
    width: "24px",
    placeItems: "center",
    marginRight: "12px",
    marginTop: "-4px",
    "&.selected": {
      "& img": {
        filter: 'brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(409%) hue-rotate(1deg) brightness(96%) contrast(101%)'
      }
    },
    "& img": {
      height: "100%",
      width: "100%",
    },
  },

  "&.lockedMenuOuter": {
    gap: "15px",
    display: "flex",
    flexDirection: "row",
    fontSize: "12px",
    justifyContent: "space-between",
    pointerEvents: "none",
    cursor: "auto",
    "&:hover": {
      color: "inherit !important",
      background: "none !important",
      transition: "none",
      "& img": {
        filter: 'none'
      },
    },
  },
  "& .lockedMenuInner": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.opacity": {
      opacity: "0.65",
    },
    "&:hover": {
      color: "inherit !important",
      background: "none !important",
      transition: "none",
      "& img": {
        filter: 'none'
      },
    },
  },
  "& .unlockContainer": {
    padding: "2px",
    height: "24px",
    width: "24px",
    minHeight: "24px",
    minWidth: "24px",
    boxSizing: "border-box",
    backgroundImage: `url(${LockIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    cusrsor: "pointer",
    transition: "all 0.1s ease-in",
    "&:hover": {
      backgroundImage: `url(${LockIconYellow})`,
    },
  },
}));

const MobileMenuItemWrapper = styled(Box)(({ theme }) => ({
  "& .sb-userinfo-b": {
    width: "100%",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FAF9",
  },
  "& .sb-userinfo-b-buyer": {
    textAlign: "center",
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: "420",
    color: colors().ultraDarkGreen,
    textTransform: 'uppercase',
    marginTop: "5px",
    wordBreak: 'break-all'
  },
  "& .sb-userinfo-b-supplier": {
    textAlign: "center",
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: "420",
    color: "#ACACAC",
    textTransform: 'uppercase',
    marginTop: "5px",
    wordBreak: 'break-all'
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 14,
  padding: 0,
  margin: "0 10px",
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: "3px",
    paddingTop: "2px",
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors().darkGold,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: colors().darkGold,
    boxSizing: 'border-box',
  },
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#1C1917",
    backgroundColor: "#E7DBB6",
    fontSize: "12px",
    lineHeight: "17px",
    textTransform: "uppercase",
    fontWeight: 450,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#E7DBB6",
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
  {
    marginTop: '0px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
  {
    marginBottom: '0px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
  {
    marginLeft: '0px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
  {
    marginRight: '0px',
  },
});


export default (NavbarBeforeLogin)