import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import React, { KeyboardEvent } from 'react';
import { SearchFilterResponse, SuggestionResponse } from "../../../blocks/utilities/src/typeInterface";
import { apiCall, handleExpiredToken, onHandleNavigation } from "../../../blocks/utilities/src/CommonFunctions";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openModal: boolean;
  onHandleCloseModal: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  searchInput: string;
  searchValue: string;
  [key: string]: any;
  showAllFilters: boolean;
  recentSearchList: string[];
  popularSearchList: string[];
  suggestionList: SuggestionResponse;
  openSearchAlert: boolean;
  anchorEl: any;
  showData: boolean;
  searchFilterResult: SearchFilterResponse;
  showEmptySearchUi: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

export default class AdvancedSearchModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  anchorRef: React.RefObject<HTMLDivElement>;

  getRecentSearchesApi: string = "";
  getPopularSearchesApi: string = "";
  suggestionsApi: string = "";
  clearRecentSearchApi: string = "";
  searchFilterApi: string = "";
  initialState: any = "";
  
  private debounceTimeout: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.anchorRef = React.createRef();
    this.anchorRef = React.createRef<HTMLInputElement>();

    this.initialState = {
      loading: false,
      searchInput: "",
      searchValue: "",
      showAllFilters: false,
      suggestionList: {
        current_suggestions: [],
        previous_searches: [],
        count: 0,
      },
      openSearchAlert: false,
      anchorEl: null,
      showData: false,
      searchFilterResult: {
        count: 0,
      },
      showEmptySearchUi: false,
    }

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
     ...this.initialState,
     recentSearchList: [],
     popularSearchList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleExpiredToken(responseJsonData, this.props);

      if (apiCallId && responseJsonData) {
        if (apiCallId === this.getRecentSearchesApi) {
          this.getRecentSearchResponse(responseJsonData);
        } else if (apiCallId === this.getPopularSearchesApi) {
          this.getPopularSearchResponse(responseJsonData);
        } else if (apiCallId === this.suggestionsApi) {
          this.suggestionsResponse(responseJsonData);
        } else if (apiCallId === this.clearRecentSearchApi) {
          this.clearRecentSearchResponse(responseJsonData);
        } else if (apiCallId === this.searchFilterApi) {
          this.searchFilterResponse(responseJsonData);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    if (this.anchorRef.current) {
      this.setState({ anchorEl: this.anchorRef.current });
    }
    this.suggestionsList("")
    this.getRecentSearchList();
    this.getPopulaSearchList();
  }

  componentDidUpdate(prevProps: Props, previousState: S) {
    if (this.anchorRef.current && (previousState.anchorEl !== this.anchorRef.current)) {
      this.setState({ anchorEl: this.anchorRef.current });
    }
    const { searchValue } = this.state;
    if (searchValue !== previousState.searchValue) {
      this.searchFilterList();
    }
  }



  getRecentSearchList = async () => {
    this.getRecentSearchesApi = await apiCall({ method: configJSON.httpGetMethod, endPoint: configJSON.getRecentSearchesEndpoint, token: true });
  }
  getPopulaSearchList = async () => {
    this.getPopularSearchesApi = await apiCall({ method: configJSON.httpGetMethod, endPoint: configJSON.getPopularSearchesEndpoint, token: true });
  }

  suggestionsList = async (query: string) => {
    this.suggestionsApi = await apiCall({ method: configJSON.httpGetMethod, endPoint: configJSON.getSuggestionsEndpoint, params: { query }, token: true });
  }

  onClearRecentSearch = async () => {
    this.clearRecentSearchApi = await apiCall({ method: configJSON.deleteMethod, endPoint: configJSON.clearRecentSearchesEndpoint, token: true });
  }

  getRecentSearchResponse = (responseData: string[]) => {
    if (responseData.length > 0) {
      this.setState({
        recentSearchList: responseData,
      })
    }
  }

  getPopularSearchResponse = (responseData: string[]) => {
    if (responseData.length > 0) {
      this.setState({
        popularSearchList: responseData,
      })
    }
  }

  suggestionsResponse = (responseData: SuggestionResponse) => {
    this.setState({
      suggestionList: responseData,
    })
  }

  clearRecentSearchResponse = (responseData: {message: string}) => {
    this.getRecentSearchList();
    this.setState({
      recentSearchList: [],
    })
    if(responseData.message) {
      toast.success(responseData.message)
    }
  }
  onHandleSearch = () => {
    if(this.state.searchFilterResult?.errors) {
      this.setState({showEmptySearchUi : true})
    }else if(this.state.searchValue) {
      this.send(onHandleNavigation(this.props, "Catalogue"))
      this.props.onHandleCloseModal()
      this.setState({
        ...this.initialState
      });
    } else {
      this.setState({openSearchAlert : true})
    }
  };

  searchFilterList = async () => {
    const {searchValue} = this.state
    const body = {
      search_keyword: searchValue,
      count: true,
    }
    this.searchFilterApi = await apiCall({ contentType: "application/json", method: configJSON.postMethod, endPoint: configJSON.searchFilterEndpoint, body: JSON.stringify(body), token: true });
  }

  searchFilterResponse = (responseData: SearchFilterResponse) => {
    this.setState({searchFilterResult: responseData, showEmptySearchUi: false});
  }

  handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    const { searchInput } = this.state;
    if (event.key === 'Enter' && searchInput.trim() !== '') {
      this.setSearchValue(searchInput.trim())
      event.preventDefault();
    }
  };

  setSearchValue = (value: string) => {
    this.setState({ searchValue: value, searchInput: "" });
  }

  handleDeleteChip = () => {
    this.setState({ searchValue: "" });
  };

  handleCloseAlert = () => {
    this.setState({ openSearchAlert: false });
  };

  handleSearchChange = (event: any) => {
    const searchTerm = event.target.value;
    this.setState({ searchInput: searchTerm, showData: true });

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.suggestionsList(searchTerm)
    }, 300);
  };

  handleShowAllFilters = () => {
    this.setState((prev) => ({ showAllFilters: !prev.showAllFilters }));
  };

  handleTextFieldFocus = () => {
    this.setState({ showData: true });
  };

  handleTextFieldBlur = (event: any) => {
    event.preventDefault()
    this.setState({ showData: false });
  };
  // Customizable Area End
}
