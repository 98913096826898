import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify";

const apiCall = async (data: {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: any;
  params?: any;
  token?: boolean;
}): Promise<string> => {
  try {
    const { contentType, method, endPoint, body, params, token } = data;
    
    const header:any = {
      token: token && await getStorageData("accessToken"),
    };

    const finalEndPoint = params ? endPoint + filterNullObj(params) : endPoint;
    
    if (contentType) {
      header["content-type"] = contentType;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    
    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;

  } catch (error) {
    throw new Error("Failed to resolve API call");
  }
};

function filterNullObj(data: any): any {
  const keys = Object.keys(data);
  let url: any = "";
  for (let key of keys) {
    if (!!data[key]) {
      if (url) url = url + "&" + `${key}=${data[key]}`;
      else url = url + `?${key}=${data[key]}`;
    }
  }
  return url;
}

const getActivePath = (props: any) => {
  return props.navigation.history.location.pathname.slice(1)

    ;
};

const handleErrors = (error: any, touch: any) => {
  return touch && Boolean(error);
};

const handleHelperText = (text: any, touch: any) => {
  return touch && text;
};

const onHandleNavigation = (props: any, path: string, dynamicPath?: string, payload?: any) => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), path);
  message.addData(getName(MessageEnum.NavigationPropsMessage), props);
  message.addData(getName(MessageEnum.NavigationScreenNameMessage), dynamicPath);

  if (payload) {
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), payload);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  }

  return message;
};

const handleClearStorage = () => {
  removeStorageData("accessToken");
  removeStorageData("accountDetails");
};
const handleClearStorageAndRedirectLogin = (props: any) => {
  handleClearStorage();
  props.navigation.navigate("EmailAccountLoginBlock");
};


const handleExpiredToken = (response: any, props: any) => {
  if (
    response &&
    response.errors &&
    response.errors.length
  ) {
    if (response.errors[0].token) {
      toast.error(response.errors[0].token ?? "Invalid Token OR Expired Token");
      handleClearStorageAndRedirectLogin(props)
    }
  }
  return false;
};

const handleUndefinedData = (value: any, elseValue: any) => {
  if (Array.isArray(value)) {
    return value.length > 0 ? value : elseValue;
  } else {
    return !!value ? value : elseValue;
  }
}

function isDiff(original: any, diff: any) {
  if (typeof original === 'string') return false;
  let t = (original === null || original === undefined) ? [] : original;
  if (t.length !== diff.length) return true;
  if (t.length === 0 && diff.length === 0) return false;

  for (let i = 0; i < t.length; i++) {
    if (!t.includes(diff[i])) return true;
  }
  return false;
}

function getDiffs(original: any, diff: any): any {
  const diffKeys = Object.keys(diff);
  let obj: any = {};
  for (let key of diffKeys) {
    if (original[key] !== diff[key] && diff[key] !== undefined) {
      if (diff[key] instanceof Array) {
        if (isDiff(original[key], diff[key])) obj[key] = diff[key];
      } else if ((!original.hasOwnProperty(key) && diff[key] != null)
        || (original.hasOwnProperty(key) && (String(original[key]) !== String(diff[key])))) {
        obj[key] = diff[key];
      }
    }
  }
  return obj;
}

const convertFormData = (attrs: any) => {
  let formData = new FormData();
  Object.keys(attrs).forEach((key: any) => {
    if (attrs[key] instanceof File) {
      formData.append(
        `data[attributes][${key}]`,
        attrs[key],
        attrs[key].name
      );
    } else if (Array.isArray(attrs[key])) {
      const arrField = attrs[key];
      if (arrField.length) {
        arrField.forEach((document: any) => {
          formData.append(`data[attributes][${key}][]`, document);
        });
      } else {
        formData.append(`data[attributes][${key}]`, arrField);
      }
    } else {
      formData.append(`data[attributes][${key}]`, attrs[key]);
    }
  });

  return formData;
}

const allowNumberWithSpaceValidation = (e: any) => {
  const keyCode = e.keyCode || e.which;
  const pattern = /^[+\d\-\s]+$/i;
  const isValid = pattern.test(String.fromCharCode(keyCode));
  if (!isValid) {
    e.preventDefault();
  }
};

const mockApiCallData = (instance: any, apiCallId: string, apiData: any, responseType: number) => {
  const msgDeviceTokenAPI = new Message(
    getName(MessageEnum.RestAPIResponceMessage)
  );
  msgDeviceTokenAPI.addData(
    getName(MessageEnum.RestAPIResponceDataMessage),
    msgDeviceTokenAPI.messageId
  );
  msgDeviceTokenAPI.addData(
    getName(responseType),
    apiData
  );
  instance[apiCallId] = msgDeviceTokenAPI.messageId;

  runEngine.sendMessage("Api Test", msgDeviceTokenAPI);
};

const handleApiError = (errors: any) => {
  const isArrayOfStirng = typeof errors[0] === 'string';

  const errorMessages = isArrayOfStirng ? errors.join(", ") : errors?.map((error: any) => Object.values(error)).flat().join(", ");
  toast.error(errorMessages);
  return "";
};

function getLocalStorageData(key:string, parseToJson:boolean = false){
  const data = localStorage.getItem(key) || null;
  if(parseToJson && data){
    return JSON.parse(data)
  }else{
    return data;
  }
  return null;
}

export {
  apiCall,
  filterNullObj,
  getActivePath,
  handleErrors,
  handleHelperText,
  onHandleNavigation,
  handleExpiredToken,
  handleUndefinedData,
  isDiff,
  getDiffs,
  convertFormData,
  allowNumberWithSpaceValidation,
  mockApiCallData,
  handleApiError,
  handleClearStorage,
  handleClearStorageAndRedirectLogin,
  getLocalStorageData
};