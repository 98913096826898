import React from "react";
// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import Grid from '@mui/material/Grid';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {
  createTheme,
} from "@mui/material/styles";
//istanbul ignore next
const CheckboxGroup = ( { title, items }: { title: string; items: any[] }) => (
  <Accordion
    sx={{
      backgroundColor: "#F5F5F5",
      border: "none",
      boxShadow: "none",
      "&::before": { display: "none" },
    }}
  >
    <AccordionSummary  data-testid="accordion-summary"
      expandIcon={<ExpandCircleDownOutlinedIcon sx={{color:"#44403C" }} />}
      aria-controls="panel2-content"
      id="panel2-header"
      sx={{
       
        marginBottom: "0", 
     
      }}
    >
       <Typography sx={{fontSize: "14px", fontWeight: "450", lineHeight: "19.6px" , color: "#292524" }}> {title}</Typography>
     
    </AccordionSummary>
    <AccordionDetails >
      <FormGroup>
        {items.map((item:any) => (
          <Box
            key={item.label}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              lineHeight: "19.6px",
              "&.Mui-expanded": {
          marginBottom: "0", 
          marginTop: "0",
          paddingBottom: "0",
          paddingTop: "0"
        },
            }}
          >
            <FormControlLabel
              control={<Checkbox defaultChecked={item.checked}  sx={{
                color: "#A8A29E", 
                '&.Mui-checked': {
                  color: "#C29E40",
                },
              }}    />}
              label={item.label}
              data-testid={`label-${item.label}`}
            />
            <Typography sx={{fontSize:"12px", fontWeight: "390" , color:"#78716C" }}>{item.count}</Typography>
          </Box>
        ))}
     <Typography variant="h6" component="h6" sx={{fontSize: "12px" , fontWeight: "420px", color: "#C29E40", lineHeight:"16.8px"}} data-testid="more-label">
  MORE
</Typography>
      </FormGroup>
    </AccordionDetails>
  </Accordion>
);

const RangeInput = ( { label }: { label: string }) => (
  <Accordion
    sx={{
      backgroundColor: "#F5F5F5",
      border: "none",
      boxShadow: "none",
      "&::before": { display: "none" },
     
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandCircleDownOutlinedIcon sx={{color:"#44403C"}}/>}
      aria-controls="panel2-content"
      id="panel2-header" 
    >
      <Typography sx={{fontSize: "14px", fontWeight: "450", lineHeight: "19.6px" , color: "#292524"}}> {label}</Typography> 
    </AccordionSummary>
    <AccordionDetails
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
       
      }}
    >
      <TextField id="outlined-basic-from" placeholder="from" variant="outlined" sx={{border: "1px solid #E2E8F0"}} />
      <Box mx={2} component="span">
        -
      </Box>
      <TextField id="outlined-basic-to" placeholder="to"  variant="outlined" sx={{border: "1px solid #E2E8F0"}} />
    </AccordionDetails>
  </Accordion>
);
//istanbul ignore next
const RadioGroupComponent = ({ title, options, children }: { title: string; options: any[];children?: React.ReactNode; }) => (
  <Accordion
    sx={{
      backgroundColor: "#F5F5F5",
      border: "none",
      boxShadow: "none",
      "&::before": { display: "none" },
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandCircleDownOutlinedIcon sx={{color:"#44403C"}}/>}
      aria-controls="panel2-content"
      id="panel2-header"
    >
      <Typography sx={{fontSize: "14px", fontWeight: "450" , lineHeight: "19.6px", color: "#292524"}}> {title}</Typography> 
    </AccordionSummary>
    <AccordionDetails >
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={options[0].value}
        name="radio-buttons-group"
      >
        {options.map((option:any) => (
          <Box
            key={option.value}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
               lineHeight: "19.6px"
            }}
          >
            {" "}
            <FormControlLabel
              value={option.value}
              control={<Radio sx={{
                color: "#D6D3D1",
                '&.Mui-checked': {
                  color: "#C29E40", 
                },
              }}  />}
              label={option.label} 
            />
            <Typography sx={{fontSize:"12px", fontWeight: "390" , color:"#78716C" }}>{option.count}</Typography> 
          </Box>
        ))}
      </RadioGroup>
      {children}
    </AccordionDetails>
  </Accordion>
);
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
  const {fabricFormationItems, materialTypeItems, colorItems, dyedNonDyedItems,patternItems, stretchOptions, materialCertificationItems, location, pcverified, useForFabric, sampleOffering, availabilityByMoq, price} = this.state
    return (
      < >
  <Box sx={{ flexGrow: 1 }} >
          <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={3} xs={12} style={webstyle.filterArea}>
          
                <Box  >
                  <Box style={webstyle.filterHead}>
                    <h3 style={webstyle.filterTitle}>Filter by </h3>
                    <p style={webstyle.filterText}>Clear all filters</p>
                  </Box>
                  <CheckboxGroup
                    title="MATERIAL TYPE"
                    items={materialTypeItems}
                  />
                  <CheckboxGroup
                    title="FABRIC TYPE"
                    items={fabricFormationItems}
                  />
                  <RangeInput label="WIDTH (CM)" />
                  <RangeInput label="LENGTH (M)" />
                  <RadioGroupComponent
                    title="AVAILABILITY BY MOQ	"
                    options={availabilityByMoq}
                  />
                  <RadioGroupComponent
                    title="PRICE"
                    options={price}>
                       <Box sx={{display: "flex", alignItems: "center"}}>
                    <TextField
                      id="outlined-basic"
                      placeholder="from"
                      variant="outlined"
                      sx={{ border: "1px solid #E2E8F0" }}
                    />
                    <Box mx={2} component="span">
                      -
                    </Box>
                    <TextField
                      id="outlined-basic"
                      placeholder="to"
                      variant="outlined"
                      sx={{ border: "1px solid #E2E8F0" }}
                    />
                  </Box></RadioGroupComponent>
                   
                  
                  
                  <CheckboxGroup title="COLOR" items={colorItems} />
                  <CheckboxGroup
                    title="PATTERN/PROPERTIES"
                    items={patternItems}
                  />
                  <RadioGroupComponent
                    title="STRETCH"
                    options={stretchOptions}
                  />
                  <CheckboxGroup
                    title="DYED/NON-DYED"
                    items={dyedNonDyedItems}
                  />
                  <CheckboxGroup
                    title="MATERIAL CERTIFICATION"
                    items={materialCertificationItems}
                  />
                   <CheckboxGroup
                    title="LOCATION"
                    items={location}
                  />
                    <RadioGroupComponent
                    title="PC VERIFIED"
                    options={pcverified}
                  />
                     <CheckboxGroup
                    title="USE FOR FABRIC"
                    items={useForFabric}
                  />
                     <RadioGroupComponent
                    title="SAMPLE OFFERING"
                    options={sampleOffering}
                  />
                </Box>
             
            </Grid>

          
       
          </Grid>
        </Box>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const webstyle = {
  filterArea: {
    backgroundColor: "#F5F5F5",
    border: "1px solid #D6D3D1",
    width: "100%",
    
  },
  filterHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
   padding: "0px 10px",
  },
  filterTitle:{
 color: "#1C1917" ,
 fontSize: "24px",
 fontWeight: "600",
 fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  filterText:{
    color: "#C29E40",
    fontWeight: "420",
    fontSize: "12px",
    cursor: "pointer"
  }
  
};
// Customizable Area End
