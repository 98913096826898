import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
export interface Item {
  title: string;
  image: string;
  description: string;
  price: string;
  available: string;
}


interface Picture {
  id: number;
  url: string;
}

interface TechnicalAndCareSheet {
  id: number;
  url: string;
}

 export interface CatalogueAttributes {
  catalogue_id: string;
  vendor: string;
  manufacturer: string;
  catalogue_type: string;
  material_name: string;
  display_name: string;
  short_description: string;
  full_description: string;
  material_type: string;
  fabric_type: string;
  fabric_formation: string;
  width: string;
  offer_sample: string;
  currency: string;
  colour: string;
  dyed_non_dyed: string;
  material_certification: string;
  composition: string;
  comments: string;
  production_rate: string;
  country_of_origin: string;
  transparency: string;
  stretch: string;
  wash_dry_clean: string;
  tumble_dry: string;
  iron_temperature: string;
  additional_care_instructions: string;
  pc_verified: string;
  notify_admin_for_quantity_below: number;
  order_minimum_quantity: number;
  order_maximum_quantity: number;
  allowed_quantities: number;
  not_returnable: boolean;
  disable_wishlist_button: boolean;
  pictures: Picture[];
  technical_and_care_sheet: TechnicalAndCareSheet;
}

export interface CatalogueItem {
  id: string;
  type: string;
  attributes: CatalogueAttributes;
}

export interface CatalogueData {
  data: CatalogueItem[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  loading?: boolean;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  page:number,
  per_page: number,
  activeTiles: boolean,
  activeGridImage: boolean,
  catalogueDataMain: CatalogueItem[];
   loading: boolean;
   isModalOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  getCatalogueDataApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      page:1,
      per_page: 20,
      activeTiles: false,
      activeGridImage: true,
      catalogueDataMain: [],
      loading: false,
      isModalOpen: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getProductList()
    const authToken = await getStorageData("accessToken");
    this.setState({ 
      token: authToken
    });
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
    if (this.isValidResponse(responseJson)) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }
    if (this.isInValidResponse(responseJson)) {
      this.apiFailureCallBacks(apiRequestCallId);
    }
  }
    // Customizable Area End
  }

  // Customizable Area Start

  isValidResponse = (responseJson: CatalogueData) => {
    return responseJson &&responseJson.data;
  };

  isInValidResponse = (responseJson: CatalogueData) => {
    return responseJson
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: CatalogueData) => {
    if (this.getCatalogueDataApiCallId === apiRequestCallId) {
     this.setState({
       catalogueDataMain: responseJson.data,
       loading: false
     },() => {
      console.log("@@@ catalogue data====>", this.state.catalogueDataMain)
     })
    }
  }

  apiFailureCallBacks = (apiRequestCallId: string) => {
    
  }


  doGetCatalogueListWithoutLogin(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token:  getStorageData("accessToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCatalogueDataApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


  getProductList(){
    this.setState({loading: true})
    this.doGetCatalogueListWithoutLogin({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.productAPiEndPoint,
    });

  }

  handleActiveGrid = () => {
    this.setState({
      activeTiles: false,
      activeGridImage: true,
    });
  };

  handleActiveTiles = () => {
    this.setState({
      activeTiles: true,
      activeGridImage: false,
    });
  };
  
  handleCloseModal = () => {
    this.setState({
      isModalOpen: false
    })
  }

  handleNavigateLogin = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleNavigateSignup = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistration");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleLoginSignup = () => {
    if(this.state.token){
      this.setState({
        isModalOpen: false
      })
    }else{
      this.setState({
        isModalOpen: true
      })
  }
}

handleLoginSignupTiles = () => {
  if(this.state.token){
    this.setState({
      isModalOpen: false
    })
  }else{
    this.setState({
      isModalOpen: true
    })
}
}

  handleProductDescription = (item: CatalogueItem) =>{
    setStorageData("product_id", item.id);
   
    
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  } 

  handleProductDescriptionFirst = (item: CatalogueItem) => {
    setStorageData("product_id", item.id);
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
