import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Dispatch, SetStateAction } from "react";
import { ValueType } from "react-select";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface Option {
  value: string;
  label: string;
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  currencyData: Array<Option>;
  fromCurrency: string;
  toCurrency: string;
  currencyRatio: number;
  fromPickerOpen: boolean;
  toPickerOpen: boolean;
  fromValue: string;
  toValue: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultipleCurrencySupportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCurrenciesCallId: string = "";
  apiGetCurrencyRatioCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "0",
      currencyData: [],
      fromCurrency: "USD",
      toCurrency: "USD",
      currencyRatio: 1.0,
      fromPickerOpen: false,
      toPickerOpen: false,
      fromValue: "",
      toValue: "",
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getCurrencies();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.apiGetCurrenciesCallId === apiRequestCallId) {
        if (responseJson?.error) {
          this.showAlert("Error", responseJson.error);
        } else {
          let currencyData = [];
          for (const [currencyKey, value] of Object.entries(responseJson.results)) {
            currencyData.push({ value: currencyKey, label: currencyKey });
          }
          this.setState({ currencyData: currencyData });
        }
      } else if (this.apiGetCurrencyRatioCallId === apiRequestCallId) {
        if (responseJson.error) {
          this.showAlert("Error", responseJson.error);
        } else {
          for (const [currencyKey, value] of Object.entries(responseJson)) {
            this.setState({ currencyRatio: value as number });
          }
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  getCurrencies() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurrenciesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currenciesAPiEndPoint + configJSON.apiKey
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCurrenctRatio() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurrencyRatioCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (configJSON.currenciesRateAPiEndPoint + configJSON.apiKey)
        .replace("FROMCID", this.state.fromCurrency)
        .replace("TOCID", this.state.toCurrency)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleWebFromDropdownChange = (option: ValueType<Option, false>) => {
    if (option) {
      this.setState({ fromCurrency: option.value }, () => {
        this.getCurrenctRatio();
      });
    }
  };

  handleWebToDrowdownChange = (option: ValueType<Option, false>) => {
    if (option) {
      this.setState({ toCurrency: option.value }, () => {
        this.getCurrenctRatio();
      });
    }
  };

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setFromPickerOpen = (open: boolean) => {
    this.setState({
      fromPickerOpen: open,
      toPickerOpen: false,
    });
  };

  setToPickerOpen = (open: boolean) => {
    this.setState({
      fromPickerOpen: false,
      toPickerOpen: open,
    });
  };

  setFromPickerValue = (callback: Dispatch<SetStateAction<string>>) => {
    this.setState({
      fromValue: callback(this.state.fromValue) as unknown as string,
    });
  };

  setToPickerValue = (callback: Dispatch<SetStateAction<string>>) => {
    this.setState({
      toValue: callback(this.state.toValue) as unknown as string,
    });
  };

  handleFromDropdownValueChange = (value: string) => {
    this.setState({ fromCurrency: value }, () => {
      this.getCurrenctRatio();
    });
  };

  handleToDropdownValueChange = (value: string) => {
    this.setState({ toCurrency: value }, () => {
      this.getCurrenctRatio();
    });
  };

  // Customizable Area Start
  // Customizable Area End
}
