import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  colorItems: any,
  materialTypeItems: any,
  patternItems: any,
  fabricFormationItems: any
  dyedNonDyedItems: any,
  materialCertificationItems: any,
  location: any,
  useForFabric: any,
  stretchOptions: any,
  availabilityByMoq: any,
  pcverified: any,
  sampleOffering: any,
  price: any,
  label: string;
  checked: boolean;
  count: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
 
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      label: "",
      checked: false,
      count: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      colorItems: [
        { label: "White", count: "2,343", checked: true },
        { label: "Black", count: "45,667", checked: false },
        { label: "Yellow", count: "34,225", checked: false },
        { label: "Red", count: "14,225", checked: false },
        { label: "Green", count: "34,225", checked: false },
      ],
      patternItems: [
        { label: "abcd", count: "123", checked: true },
        { label: "xyzz", count: "456", checked: false },
        { label: "jklm", count: "789", checked: false },
      ],
      materialTypeItems: [
        { label: "Reused Materials/Deadstock", count: "3,444", checked: true },
        { label: "Bio-materials", count: "1,212", checked: false },
        { label: "New Sustainable Material", count: "654", checked: false },
        { label: "Recycled", count: "12,343", checked: false },
        { label: "Certified ", count: "65,894", checked: false },
      ],

      fabricFormationItems: [
        { label: "Knitted", count: "23,565", checked: true },
        { label: "Woven", count: "35,165", checked: false },
        { label: "Non-Woven", count: "65,121", checked: false },
      ],
      dyedNonDyedItems: [
        { label: "Dyed", count: "23,121", checked: true },
        { label: "Non-Dyed", count: "56.212", checked: false },
      ],
       materialCertificationItems :[
        { label: "Certified", count: "5,678", checked: true },
        { label: "Non-Certified", count: "2,233", checked: false },
      ],
      location: [
        {label: "India", count: "65,222"},
        {label: "China", count: "87,454"},
        {label: "United Kingdom", count: "43,262"},
        {label: "United States", count: "12,343"},
        {label: "Bangladesh", count: "2,44"}
      ]
  ,
  useForFabric: [
    {label: "All Interiors", count: "65,222"},
    {label: "Active Wear", count: "87,454"},
    {label: "Lingerie", count: "43,262"},
    {label: "Outerwear", count: "12,343"},
    {label: "Beachwear and Toweling", count: "2,44"}
  ],
       stretchOptions : [
        { label: "Yes", count: "56,2882", value: "Yes" },
        { label: "No", count: "22,139", value: "No" },
      ],
      availabilityByMoq: [
        { label: "Over 1000m", count: "56,2882", value: "Over 1000m" },
        { label: "Over 500m", count: "22,139", value: "Over 500m" },
        { label: "Over 100m", count: "22,139", value: "Over 100m" },
        { label: "Under 100m", count: "22,139", value: "Under 100m" },
        { label: "Under 50m", count: "22,139", value: "Under 50m" },
      ],
      price:[
        { label: "Under £5 per metre", count: "56,2882", value: "Under £5 per metre" },
        { label: "Under £10 per metre", count: "22,139", value: "Under £10 per metre" },
        { label: "Under £20 per metre", count: "22,139", value: "Under £20 per metre" },
        { label: "Under £25 per metre", count: "22,139", value: "Under £25 per metre" },
      ],
      pcverified : [
        {label: "Yes", count:"22,139", value: "Yes"},
        {label: "No", count: "56,288", value: "No"},
        {label: "In Process", count: "23,454", value: "inProcess"},
      ],
      sampleOffering: [
        {label: "Yes", count:"22,139", value: "Yes"},
        {label: "No", count: "56,288", value: "No"},
      ]

      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getAdvancedSearchList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);


      if (responseJson && responseJson.accounts) {
        if (typeof responseJson.accounts === "string") {
          alert(responseJson.accounts);
        } else {
          this.setState({ advancedsearchList: responseJson.accounts.data });
        }
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          if (apiRequestCallId === this.advancedsearchApiCallId) {
            this.showAlert("Alert", "API Error", "");
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
