import React from "react";
import { CircularProgress } from "@mui/material";

interface myProps {
  loading: boolean;
}

export default function Loader(props: myProps) {
  return props.loading ? (
    <div style={webStyles.root}>
      <div style={webStyles.circularContainer}>
        <CircularProgress size={60} sx={{ color: "#2D6A4D"}} />
      </div>
    </div>
  ) : (
    <div />
  );
}

const webStyles = {
  root: {
    position: "absolute" as const,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute" as const,
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
};