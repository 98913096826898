//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  CardMedia,
  Card,
  // Customizable Area End
  Grid,

} from "@mui/material";

// Customizable Area Start
import ReactImageMagnify from 'react-image-magnify';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { styled } from '@mui/system';
import ImageMain from '../assets/sewamo.png';
import MinusIcon from '../assets/minusIcon.svg';
import PlusIcon from '../assets/plusIcon.svg';
import ZoomIcon from '../assets/zoomIcon.svg';
import PriceIcon from '../assets/priceIcon.svg';
import UnlockIcon from '../assets/unlockIcon.svg';
import InsFist from '../assets/inspirationSecond.png';
import InsSecond from '../assets/inspirationFirst.png';
import CertificationFirst from '../assets/certificationFirst.svg';
import CertificationSecond from '../assets/certifiSecond.svg';
import CertificationThird from '../assets/certifiThird.svg';
import productImg from '../assets/productImage.svg';
import { NavigationWrapper } from "../../../blocks/navigationmenu/src/NavigationWrapper.web";

import CommonModalComponent from "../../../components/src/CommonModalComponent";

import { FavoriteBorder as FavoriteBorderIcon } from '@mui/icons-material';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  }
);
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderProductInsipiration = () => {
    const {tokenMain } = this.state
    return (
      <>
        <Wrapper className={tokenMain ? "with-token": "without-token"}>
          <Typography style={{ fontSize: "24px", fontWeight: 420, color: "#292524", textTransform: "uppercase", margin: "13px 0px 0.35em" }} align="center" gutterBottom>
            Inspirations
          </Typography>
          <InspirationContainer className={tokenMain ? "token" :"without_token"} container  spacing={{ xs: 0, sm: 0 }}>
            <div>
              <ImageContainerOne>
                <img
                  style={{ height: "305px" }}
                  src={InsFist}
                  alt="Inspiration 1"
                />
              </ImageContainerOne>
            </div>
            <InspiratinMidText>
              <TextBox>
                <TextInnerBox>
                  <InspirationComponent gutterBottom>
                  Jacket Revamp
                </InspirationComponent>
                <DescriptionComponent style={{ fontSize: "12px", fontWeight: "390", color: "#292524" }}>
                  {configJSON.InspirationText}
                </DescriptionComponent> 
                </TextInnerBox>
              </TextBox>
            </InspiratinMidText>
            <div>
              <ImageContainerOne>
                <img
                  style={{ height: "305px" }}
                  src={InsSecond}
                  alt="Inspiration 2"
                />
              </ImageContainerOne>
            </div>
          </InspirationContainer>
        </Wrapper>
        <SimilarProductWrapper className={tokenMain ? "with-similar-token" :"without-similar-token"}>
          <SimilarProductComponent align="center">
            Similar products
          </SimilarProductComponent>  
          <MainContainer>
            <GridContainerMain container  justifyContent="center" style={{ flexWrap: 'nowrap' }}>
    {items.map((item: Item, index: number) => (
                <Grid item xs={12} sm={4} md={2.4} lg={2.4} key={index}>
                  <CardMainComponent className={tokenMain ? "with_card_token":"without_card_token"}>
          <div style={{ position: 'relative' }} data-test-id="grid-navigate-id" onClick={this.handleProductDescription}>
                      <CardMediaComponent
                        image={item.image}
                      />
                      <IconButtonComponent  data-test-id="similar-product-test-id" onClick={(event) => {
                        event.stopPropagation()
                        this.handleSimilarProductLoginSignup()
                      }} style={{ height: "25px", width: "28px", position: 'absolute', top: 1, right: 0, zIndex: 1, color: 'white' }}>
              <FavoriteBorderIcon />
            </IconButtonComponent>
          </div>
          <CardContentComponent>
                      <Typography noWrap style={{ fontWeight: "420", fontSize: '16px', marginTop: "10px", fontFamily: "brandon-grotesque, sans-serif", color: "#162A26",textTransform:"uppercase" }} gutterBottom>
              {item.title}
            </Typography>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <ContainerMain>
                <ItemNew>
                  <PriceTypography>PRICE</PriceTypography>
                            <Value>
                              £0.45 /m
                            </Value>
                </ItemNew>
                <ItemNew>
                  <PriceTypography>REQUESTED</PriceTypography>
                            <Value>
                              150,000 m
                            </Value>
                </ItemNew>
              </ContainerMain>
              <CardContentRowLast>
                <div style={{ height: "14px", width: "14px", backgroundColor: "#D84539" }} />
              </CardContentRowLast>
            </div>
          </CardContentComponent>
        </CardMainComponent>
      </Grid>
    ))}
  </GridContainerMain>

</MainContainer>
        </SimilarProductWrapper>
      </>
    )
  }

  renderProductDetail = () => {
    const { productDescriptionData } = this.state
    return (
      <ProductDetailContainer container spacing={4} marginTop="50px">
        <ProductDetailsSectionContainer item>
          <ProductDetailsSection>
            <Typography style={{ color: "#292524", fontWeight: 450, fontSize: "20px" }} gutterBottom>
              Product Details
            </Typography>
            <DetailInnerContainer container spacing={2} style={{ marginTop: "34px" }}>
              {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
                <Grid container item xs={12} sm={6} spacing={2}>
                  <Grid item xs={6}>
                    <DetailItem>Fabric type</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.fabric_type.value}
                    </Typography>
                  </Grid>
                  <Grid style={{ marginLeft: "0px",paddingTop:"2px" }} container spacing={2}>
                  <Grid item xs={6}>
                      <DetailItem>Composition</DetailItem>
                    </Grid>
                    <Grid style={{ paddingLeft: "14px" }} item xs={6} container direction="column" >
                    {productDescriptionData.attributes.compositions.map((item) => (
  <Grid item key={item.id}> 
    <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
      {item.percent}% {item.value} 
    </Typography>
  </Grid>
))}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <DetailItem>Fibre pattern</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.fabric_pattern.value}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                  <DetailItem>Weight (g/cm<sup>2</sup>)</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.weight}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <DetailItem>Width (cm)</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.width}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <DetailItem>Stretch</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.stretch.value}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <DetailItem>Country of origin</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.country_of_origin}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid style={{ paddingTop: "10px" }} container item xs={12} sm={6} spacing={2}>
                  <Grid item xs={6}>
                    <DetailItem>Fibre type</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.fabric_type.value}
                    </Typography>
                  </Grid>

                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <DetailItem>Composition</DetailItem>
                    </Grid>
                    <Grid style={{ paddingLeft: "7px" }} item xs={6} container direction="column" >
                    {productDescriptionData.attributes.compositions.map((item) => (
  <Grid item key={item.id}> 
    <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
      {item.percent}% {item.value} 
    </Typography>
  </Grid>
))}
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <DetailItem>Country of origin</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.country_of_origin}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <DetailItem>Count/Tex</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                    {productDescriptionData.attributes.tex}
                    </Typography>
                  </Grid>
                </Grid>

              )}


              <Grid item xs={12} sm={0.5}>
                <div style={{ borderLeft: "1px solid #E7E5E4", height: "100%" }}></div>
              </Grid>
              {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
                <Grid container item xs={12} sm={5.5} spacing={2}>
                  <Grid item xs={6}>
                    <DetailItem>Dyed or Non-Dyed</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <MediumComponent style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.dye.value}
                    </MediumComponent>
                  </Grid>

                  <Grid item xs={6}>
                    <DetailItem>Colour</DetailItem>
                  </Grid>
                  <Grid style={{ display: "flex", alignItems: "center" }} item xs={6}>
                    <MediumComponentColorBox 
style={{
  backgroundColor: this.state.isSelected ? (this.state.selectedColor.name === "teal" ? "#FFEED8": this.state.selectedColor.name) :
    productDescriptionData.attributes.color.value.toLocaleLowerCase()}} /> <span style={{ textTransform:"capitalize", fontWeight: 390, fontSize: "16px", paddingLeft: "9px" }}>
                    {this.state.isSelected ? this.state.selectedColor.name : productDescriptionData.attributes.color.value}
                    </span>
                  </Grid>

                  <Grid item xs={6}>
                    <DetailItem>Wash/Dry clean</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <MediumComponent style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.wash_dry_clean.value}
                    </MediumComponent>
                  </Grid>

                  <Grid item xs={6}>
                    <DetailItem>Tumble dry</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <MediumComponent style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.tumble_dry === true ? "Yes" : "No"}
                    </MediumComponent>
                  </Grid>

                  <Grid item xs={6}>
                    <DetailItem>Iron temperature</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <MediumComponent style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.iron_temperature.value}
                    </MediumComponent>
                  </Grid>

                  <Grid item xs={6}>
                    <DetailItem>Bleaching</DetailItem>
                  </Grid>
                  <Grid item xs={6}>
                    <MediumComponent style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.bleaching === true ? "Yes" : "No"}
                    </MediumComponent>
                  </Grid>

                  <Grid item xs={6}>
                    <DetailItemLast>{productDescriptionData.attributes.cleaning_instruction.value ?? "Additional care instruction"}</DetailItemLast>
                  </Grid>

                </Grid>
              ) : (

                <Grid style={{ height: "100%", paddingTop: "10px" }} container item xs={12} sm={5.5} >
                  <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div item style={{ width: "50%" }}>
                      <DetailItem>Dyed or Non-Dyed</DetailItem>
                    </div>
                    <div item style={{ width: "50%" }}>
                      <MediumComponent style={{ fontSize: "16px", fontWeight: 390, color: "#292524" }}>
                        {productDescriptionData.attributes.dye.value}
                      </MediumComponent>
                    </div>
                  </div>

                  <div style={{ display: "flex", width: "100%", alignItems: "center", marginTop: "5px" }}>
                    <div item style={{ width: "50%" }}>
                      <DetailItem>Colour</DetailItem>
                    </div>
                    <div item style={{ width: "50%", display: "flex", alignItems: "center" }}>
                      <MediumComponentColorBox
                     style={{
                      backgroundColor: 
                        productDescriptionData.attributes.color.value.toLocaleLowerCase()}}
                      /> <span style={{ fontWeight: 390, fontSize: "16px", paddingLeft: "7px" }}>
                        {productDescriptionData.attributes.color.value}
                      </span>
                    </div>
                  </div>
                </Grid>
              )}
            </DetailInnerContainer>
          </ProductDetailsSection>
        </ProductDetailsSectionContainer>
        <DescriptionSectionContainer item >
          <DescriptionSection>
            <DescriptionFirstComponent>
              <div>
                <Typography style={{ color: "#292524", fontWeight: 450, fontSize: "20px" }} gutterBottom>
                  Showroom
                </Typography>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
                  <div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                    <Typography style={{ fontWeight: "450", fontSize: "14px", color: "#292524", marginRight: "5px" }}>
                      In Showroom
                    </Typography>
                    <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
                      {productDescriptionData.attributes.sample_in_showroom === true 
    ? "Yes" 
    : "No"}
                    </Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography style={{ fontWeight: "450", fontSize: "14px", color: "#292524", marginRight: "5px" }}>
                      Location: <span style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
                        {productDescriptionData.attributes.full_location}
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "27px" }}>
                <Typography style={{ color: "#292524", fontWeight: 450, fontSize: "20px" }} gutterBottom>
                  Description
                </Typography>
                <DescrptionContentComponent paragraph>
                  {productDescriptionData.attributes.full_description}
                </DescrptionContentComponent>
              </div>

            </DescriptionFirstComponent>
            <LogisticalComponent >
              <Typography style={{ color: "#292524", fontWeight: 450, fontSize: "20px" }} gutterBottom>
                Logistical Information
              </Typography>
              <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
                <div style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
                  <Typography style={{ fontWeight: "450", fontSize: "14px", color: "#292524", marginRight: "5px" }}>
                    Location:
                  </Typography>
                  <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
                   {productDescriptionData.attributes.full_location}
                  </Typography>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ fontWeight: "450", fontSize: "14px", color: "#292524", marginRight: "5px" }}>
                    Lead Time: <span style={{ fontSize: "14px", fontWeight: 390, color: "#292524" }}>
                      This product should be delivered to you in{' '}
                      <span style={{ color: '#2D6A4D', fontWeight: 'bold' }}>7 working days</span>.
                    </span>
                  </Typography>
                </div>
              </div>
            </LogisticalComponent>
          </DescriptionSection>
        </DescriptionSectionContainer>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "20px", fontWeight: 420, color: "#292524" }}>Certifications</Typography>
          <CertificationsSection>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <CertificationImage src={productDescriptionData.attributes.first_certificate_image.url} />
              <p style={{ color: "#292524", fontWeight: 420, fontSize: "14px" }}>{productDescriptionData.attributes.first_certificate_image.name}</p>
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <CertificationImage src={productDescriptionData.attributes.second_certificate_image.url} />
              <p style={{ color: "#292524", fontWeight: 420, fontSize: "14px" }}>{productDescriptionData.attributes.second_certificate_image?.name}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <CertificationImage src={productDescriptionData.attributes.third_certificate_image.url} />
              <p style={{ color: "#292524", fontWeight: 420, fontSize: "14px" }}>{productDescriptionData.attributes.third_certificate_image?.name}</p>
            </div>

          </CertificationsSection>
        </Grid>
      </ProductDetailContainer>
    )
  }

  renderThumbnailPart = () => {
    const { tokenMain, isZoomed, isOutOfStock, productDescriptionData, selectedImage } = this.state;

    const renderProductImage = () => (
      <div
        style={{
          border: '2px solid #E7E5E4',
          padding: '3px',
          marginBottom: '17px',
          position:"relative"
        }}
      >
        {isZoomed ? renderZoomedImage() :
        <ProductImage
          image={selectedImage || productDescriptionData?.attributes.pictures[0].url}
          style={{ width: '100%', height: 'auto' }}
        />}
        {isOutOfStock && (
          <Overlay>
            <OutOfStockContainer>
              <Text>Out</Text>
              <Text>Stock</Text>
            </OutOfStockContainer>
          </Overlay>
        )}
        <IconButtonComponentOne
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 2,
                "@media (max-width: 600px)": {
                  top: "5px",
                  right: "4px",
                },
              }}
              onClick={(event) => {
                event.stopPropagation()
                this.handleProductLoginSignup()
              }}
              data-test-id="login-signup-product-id"
            >
              <FavoriteBorderIcon />
            </IconButtonComponentOne>
        {renderZoomButton()}

      </div>
    );

    const renderZoomedImage = () => (
      <div style={{ width: '100%' }}>
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: 'Wristwatch by Ted Baker London',
              isFluidWidth: true,
              src: selectedImage || productDescriptionData?.attributes?.pictures[0]?.url,
            },
            largeImage: {
              src: selectedImage || productDescriptionData?.attributes?.pictures[0]?.url,
              width: 1200,
              height: 1800,
            },
            lensStyle: { backgroundColor: 'none' },
            enlargedImagePosition: 'over',
            enlargedImageContainerStyle: { backgroundColor: 'transparent' },
            isActivatedOnTouch: true,
          }}
        />
      </div>
    );

    const renderZoomButton = () => {
      const ZoomButtonComponent =  ZoomButton
      return (
        <ZoomButton className={this.state.productDescriptionData.attributes.pictures.length < 1 && "new_class"} data-test-id="zoom-click-id" onClick={this.handleZoomClick}>
          <img src={ZoomIcon} alt="Zoom Icon" />
        </ZoomButton>
      );
    };

    return (
      <WrraperThumbnail>
        <ThumbnailContainer item>
          <div style={{ width: "100%", height: "100%" }}>
             {renderProductImage()}
            
            <SmallImagesContainer container>
              {productDescriptionData?.attributes?.pictures.map((picture) => (
                <SmallImage key={picture.id} src={picture.url}
                  onClick={() => this.handleImageSelect(picture.url)}
                  style={{ border: selectedImage === picture.url ? '3px solid #E7E5E4' : 'none', padding: selectedImage === picture.url ? '3px' : 'none' }}
                data-test-id="image-select-id"
                />
              ))}
            </SmallImagesContainer>
          </div>
        </ThumbnailContainer>
        {this.renderRightSideContainer()}
      </WrraperThumbnail>
    );
  };

  renderColorPallet = () => {
    const {productDescriptionData, selectedColor, colorGradients } = this.state;
    return (
      <div >
        <Typography style={{ fontSize: "16px", fontWeight: 450, color: "#162A26" }}>COLOUR: {this.state.isSelected
        ? selectedColor.name.toUpperCase() 
        : productDescriptionData.attributes.color?.value?.toUpperCase()}</Typography>
        <Grid style={{ alignItems: "center", width: "291px", justifyContent: "space-between" }} container>
          {colorGradients.map( (item) => (
            <ColorButtonMain
              data-test-id="code-select-id"
              key={item.name}
              onClick={() => this.handleColorSelect(item)}
              className={this.state.selectedColor.name === item.name ? 'selected' : ''}
            >
              <ColorButton
                className={this.state.selectedColor.name === item.name ? 'selected' : ''}
                gradient={item.gradient}
              />
            </ColorButtonMain>
          ))}
        </Grid>
      </div>
    )
  }

  renderMaterialLcaDataWithButton = () => {
    const { tokenMain } = this.state;
    return (
      <Grid item>
        <Typography style={{ fontSize: "16px", fontWeight: 450, color: "#162A26", textTransform: "uppercase" }} marginTop={4}>
          Material LCA data
        </Typography>
        <MaterialLcaInnerContainer >
          <StyledContainer>
            <StyledContent>
              <div>
                <div style={{ fontSize: '10.1px' }}>Total CO2 Emissions</div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                  <img style={{ width: '20px', height: '20px' }} src={ImageMain} alt="" />
                  <p style={{ paddingLeft: '4px' }}>7676</p>
                </div>
              </div>
              <div>
                <div style={{ fontSize: '10.1px' }}>Water saved</div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                  <img style={{ width: '20px', height: '20px' }} src={ImageMain} alt="" />
                  <p style={{ paddingLeft: '4px' }}>7676</p>
                </div>
              </div>
              <div>
                <div style={{ fontSize: '10.1px' }}>Chemicals & dyes avoided</div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                  <img style={{ width: '20px', height: '20px' }} src={ImageMain} alt="" />
                  <p style={{ paddingLeft: '4px' }}>7676</p>
                </div>
              </div>
            </StyledContent>
            <UnlockOverlay>
              <ButtonComponentMain>
                <img src={UnlockIcon} />
                Coming soon
              </ButtonComponentMain>
            </UnlockOverlay>
          </StyledContainer>
          {!tokenMain
            &&
            <ButtonContainer>
              <Button  onClick={this.handleOpenModalOne} data-test-id="order-sample-modal-test-id" className='button_first' variant="text">Order a sample</Button>
              <Button onClick={this.handleOpenModalTwo} data-test-id="add-basket-modal-test-id" variant="text" style={{ marginLeft: "5px" }} className='button_first'>Add to basket</Button>
              <Button onClick={this.handleOpenModalThree} data-test-id="buy-now-modal-test-id" style={{ marginLeft: "5px" }} className='button_last' variant="text">
                Buy Now
              </Button>
            </ButtonContainer>}
          {tokenMain &&
            <ButtonContainerAfterLogin>
              <Button data-test-id="order-sample-test-id" className='button_first_after_login' variant="text">Order a sample</Button>
              <Button variant="text" style={{ marginLeft: "5px" }} className='button_first_after_login'>Add to basket</Button>
              <Button data-test-id="buy-now-test-id" style={{ marginLeft: "5px" }} className='button_last_after_login' variant="text">
                Buy Now
              </Button>
            </ButtonContainerAfterLogin>
          }
        </MaterialLcaInnerContainer>
      </Grid>
    )

  }

  renderMeterField = () => {
    const { productDescriptionData } = this.state;
    return (
      <>
        {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
          <Grid item style={{ lineHeight: "15px" }}>
            <PriceTag>£{productDescriptionData?.attributes?.price}</PriceTag>
            <p style={{ fontWeight:420, margin: "0px", color: "#44403C" }}>/metre</p>
          </Grid>
        ) : (
          <Grid item style={{ lineHeight: "15px" }}>
            <PriceTag>£{productDescriptionData.attributes?.price}</PriceTag>
            <p style={{ fontWeight:420, margin: "0px", color: "#44403C" }}>/KG</p>
          </Grid>
        )}
      </>
    )
  }

  renderPriceBlock = () => {
    const { productDescriptionData,tokenMain } = this.state;
    const unit = productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? "metres" : "KG";
    const price = (productDescriptionData.attributes?.discounted_price * this.state.meters).toFixed(2);

    return (
      <PriceComponent className="price_with_token" item>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
          whiteSpace: "nowrap" ,
    
        }}
      >
        <img
          style={{ width: "13.17px", height: "13.17px", marginRight: "8px" ,paddingLeft: "9px"}}  // Add margin to space image from text
          src={PriceIcon}
          alt="Price Icon"
        />
                <Typography
          style={{
            fontSize: "16px",
            fontWeight: 420,
            color: "#292524",
            margin: "0",  
          }}
        >
          Price Summary: £{price} for {this.state.meters} {unit}
        </Typography>
      </div>
    </PriceComponent>
    )
  }

  renderRightSideContainer = () => {
    const { tokenMain, productDescriptionData } = this.state;
    return (
      <RightSideDataComponent item style={{ marginTop: "0px" }} >
        <Typography style={{ fontSize: "16px", fontWeight: 420, color: "#292524" }} gutterBottom>
          Circular Marketplace / <span style={{ color: "#292524" }}>{productDescriptionData?.attributes?.material_type?.value}</span>
        </Typography>
        <Typography style={{ fontSize: "24px", fontWeight: 420, color: "#292524", marginTop: "7px" }} gutterBottom>
          {productDescriptionData?.attributes?.display_name}
        </Typography>
        <Typography style={{ color:"#292524",fontSize: "16px", fontWeight: 420, marginTop: "15px" }} >
          PC ID <span style={{ color: "#2D6A4D" }}>#{productDescriptionData?.attributes?.pc_id}</span>
        </Typography>
        <Grid container style={{ marginTop: "5px" }} alignItems="center" spacing={2}>
          <Grid item>
            <DiscountedPrice > £{(typeof productDescriptionData.attributes.discounted_price === 'number' && !isNaN(productDescriptionData.attributes.discounted_price) 
    ? productDescriptionData.attributes.discounted_price.toFixed(0) 
    : productDescriptionData.attributes.discounted_price)}</DiscountedPrice>
          </Grid>
          {productDescriptionData.attributes?.discount .length > 0 ? (
          this.renderMeterField()):("")}
          <Grid item>
          {productDescriptionData.attributes?.discount .length > 0 ? (
                <BoxComponent>
              <Typography style={{ color: "#528A6F", fontSize: "16px", fontWeight: 420 }}>
               
                {parseFloat(productDescriptionData.attributes?.discount).toFixed(0)}% off
              </Typography>
            </BoxComponent>   
                ):("")}
           
          </Grid>
        </Grid>
        {this.renderColorPallet()}
        <Typography style={{ color: "#162A26", fontWeight: 450, fontSize: "16px", textTransform: "uppercase" }} marginTop={2}>
          Metres required
        </Typography>
        <AvailableQuantityComponent className={tokenMain ? "with-token-moq":"without-token-moq"}>
          {productDescriptionData?.attributes?.catalogue_type.toLowerCase() === "cotton" ? (
            <>
              <Typography style={{ fontSize: "14px", fontWeight: 450, color: "#292524" }} marginTop={2}>
                MOQ: <span style={{ fontSize: "14px", fontWeight: 390 }}>{productDescriptionData.attributes.order_minimum_quantity.toLocaleString()} metres</span>
              </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: 450, color: "#162A26" }} marginTop={2}>
                Available quantity: <span style={{ fontSize: "14px", fontWeight: 390 }}>{productDescriptionData.attributes?.available_quantity.toLocaleString()} metres</span>
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ fontSize: "14px", fontWeight: 450, color: "#292524" }} marginTop={2}>
                MOQ: <span style={{ fontSize: "14px", fontWeight: 390 }}>{productDescriptionData.attributes.order_minimum_quantity.toLocaleString()} KG</span>
              </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: 450, color: "#292524" }} marginTop={2}>
                Available quantity: <span style={{ fontSize: "14px", fontWeight: 390 }}>{productDescriptionData.attributes?.available_quantity.toLocaleString()} KG</span>
              </Typography>
            </>
          )}
        </AvailableQuantityComponent>
        <Grid style={{ paddingLeft: "16px", marginTop: "6px" }} container alignItems="center" spacing={2}>
          <MeterWrapper container alignItems="center">
            <Box style={{ display: "flex", justifyContent: "space-between", border: "1px solid #D6D3D1", borderRight: "none", width: "254px", height: "46px", alignItems: "center" }}>
              <Grid style={{ paddingLeft: "5px" }} item>
                <StyledIconButtonOne data-test-id="decrease-test-id" onClick={this.handleDecrease}>
                  <img src={MinusIcon} />
                </StyledIconButtonOne>
              </Grid>
              <Grid item>
                <MeterInput>{this.state.meters}</MeterInput>
              </Grid>
              <Grid item style={{ paddingRight: "5px" }}>
                <StyledIconButtonTwo data-test-id="increase-test-id" onClick={this.handleIncrease}>
                  <img src={PlusIcon} />
                </StyledIconButtonTwo>
              </Grid>
            </Box>
            {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
              <MeterComponent item>
                <UnitBox>Metres</UnitBox>
              </MeterComponent>
            ) : (
              <MeterComponent item>
                <UnitBox>KG</UnitBox>
              </MeterComponent>
            )}
          </MeterWrapper>
          {!tokenMain &&
            <>
              {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
                <TotalWeightComponent>
                  <TotalWeightInnerComponentTypography component="label" >
                    Total weight:
                  </TotalWeightInnerComponentTypography>
                  <Box display="flex" alignItems="center">
                    <WeightBox>
                    <WeightInnerTypography>
                    {
   !isNaN(productDescriptionData.attributes.weight) &&
   !isNaN(this.state.meters) &&
   ((parseFloat(productDescriptionData.attributes.weight) / 10000000) * parseFloat(this.state.meters)).toFixed(5)
}
</WeightInnerTypography>
                    </WeightBox>
                    <UnitBoxComponent>
                      <Typography className="unit-box" sx={{ fontWeight: 420, fontSize: '14px', color: '#162A26' }}>KG</Typography>
                    </UnitBoxComponent>
                  </Box>
                </TotalWeightComponent>

              ) : ("")}
            </>
          }

          {tokenMain &&
            <>
              {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
                <TotalWeightComponentAfterLogin>
                  <TotalWeightInnerComponentAfterLogin component="label" >
                    Total weight:
                  </TotalWeightInnerComponentAfterLogin>
                  <Box display="flex" alignItems="center">
                    <WeightBoxAfterLogin>
                    <WeightInnerTypography>
                    {
   !isNaN(productDescriptionData.attributes.weight) &&
   !isNaN(this.state.meters) &&
   ((parseFloat(productDescriptionData.attributes.weight) / 10000000) * parseFloat(this.state.meters)).toFixed(5)
}

</WeightInnerTypography>                  
                    </WeightBoxAfterLogin>
                    <UnitBoxComponentAfterLogin>
                      <Typography className="unit-box" sx={{ fontWeight: 450, fontSize: '16px', color: '#162A26' }}>KG</Typography>
                    </UnitBoxComponentAfterLogin>
                  </Box>
                </TotalWeightComponentAfterLogin>
              ) : ""}
            </>
          }
        </Grid>
        {this.renderPriceBlock()}
        {this.renderMaterialLcaDataWithButton()}
      </RightSideDataComponent>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationWrapper {...this.props}>
        <ContainerRootMain  className={this.state.tokenMain ? "container-product-with-token" : "container-product-without-token"}>
          {this.renderThumbnailPart()}
          {this.renderProductDetail()}
          {this.renderProductInsipiration()}
          <CommonModalComponent
          data-test-id="modalproduct-click-id"
            open={this.state.isOpen}
            handleClose={this.handleProductCloseModal}
            title="Access ProcureCircular"
            content={configJSON.ModalContent}
            primaryButtonLabel="LOG IN"
            secondaryButtonLabel="SIGN UP"
            primaryAction={this.handleProductNavigateLogin}
            secondaryAction={this.handleProductNavigateSignup}
          />
        </ContainerRootMain>
      </NavigationWrapper>



      // Customizable Area End
    );
  }
}

// Customizable Area Start


const ContainerRootMain = styled(Container)({
  "&.container-product-with-token":{
    flexGrow: 1,
    padding: "24px",
  
    '&.MuiContainer-root': {
      maxWidth: '100%',
      padding: "24px 49px",
       marginTop:"48px"
    },
  
    '@media (max-width: 599px)': {
      '&.MuiContainer-root': {
        maxWidth: '100%',
        padding: '9px',
      },
    },
  
    '@media (min-width: 600px) and (max-width: 1199px)': {
      '&.MuiContainer-root': {
        maxWidth: '892px',
      },
    },
    '@media screen and (min-width: 1920px) and (min-height: 1200px)': {
      '&.MuiContainer-root': {
        maxWidth: '1600px',
      },
    },
  
    '@media screen and (min-width: 1920px) and (max-height: 1080px)': {
      '&.MuiContainer-root': {
        maxWidth: '1843px',
      },
    },
  
    '@media (min-width: 2560px)': {
      '&.MuiContainer-root': {
        maxWidth: "2765px"
      },
    },

  },
  "&.container-product-without-token":{

    flexGrow: 1,
    padding: "24px",
  
    '&.MuiContainer-root': {
      maxWidth: '100%',
      padding: "24px 49px",
  
    },
  
    '@media (max-width: 599px)': {
      '&.MuiContainer-root': {
        maxWidth: '100%',
        padding: '9px',
      },
    },
  
    '@media (min-width: 600px) and (max-width: 1199px)': {
      '&.MuiContainer-root': {
        maxWidth: '892px',
      },
    },
    '@media screen and (min-width: 1920px) and (min-height: 1200px)': {
      '&.MuiContainer-root': {
        maxWidth: '1600px',
      },
    },
  
    '@media screen and (min-width: 1920px) and (max-height: 1080px)': {
      '&.MuiContainer-root': {
        maxWidth: '1843px',
      },
    },
  
    '@media (min-width: 2560px)': {
      '&.MuiContainer-root': {
        maxWidth: "2765px"
      },
    },
  }

 
});

const WrraperThumbnail = styled('div')({
  flexWrap: "nowrap",
  display: "flex",
  '@media (min-width: 320px) and (max-width: 799px)': {
    flexWrap: "wrap !important",
  },

  '@media (min-width: 800px) and (max-width: 1199px)': {
    flexWrap: "wrap !important",
  },

});

const AvailableQuantityComponent = styled('div')({
  "&.with-token-moq":{
    display: "flex", width: "52%", justifyContent: "space-between",
    '@media (min-width: 320px) and (max-width: 470px)': {
    width:"83%"
    },
    '@media (min-width: 800px) and (max-width: 1199px)': {
      width: "43%",
    },
    '@media (min-width: 1200px) and (max-width: 1300px)': {
      width: "60%",
    },
  },"&.without-token-moq":{
    display: "flex", width: "52%", justifyContent: "space-between",
    '@media (min-width: 320px) and (max-width: 470px)': {
    width:"83%"
    },
    '@media (min-width: 800px) and (max-width: 1199px)': {
      width: "43%",
    },

  }

})
const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: '#D9D9D980',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  width: '93%',
});

const OutOfStockContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '92px',
  width: '295px',
  border: '1px solid #1E293B',
  backgroundColor: '#FFFFFF',
});

const Text = styled('p')({
  margin: 0,
  color: '#000',
});

const ColorButtonMain = styled("div")({
  cursor:"pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "28px",
  height: "28px",
  minWidth: "28px",
  gap: "8px",
  opacity: 1,
  border: "1px solid #D6D3D1",

  "&:hover": {
    border: "1.14px solid #BA902F",
    opacity: 1,
  },

  "&.selected": {
    border: "1.14px solid #BA902F",
    opacity: 1,
    width: "32px",
    height: "32px",
    minWidth: "32px",
    margin: "4px",
  }
});

const ColorButton = styled(Button)(({ gradient }) => ({
  cursor: "pointer",
  width: "20px",
  height: "20px",
  minWidth: "20px",
  margin: "4px",
  background: gradient,
  borderRadius: "0",

  "&.selected": {
    opacity: 1,
    width: "27.43px",
    height: "27.43px",
    minWidth: "27.43px",
    background: gradient,
  }
}));

const MediumComponent = styled(Typography)({
  fontSize: "16px",
  fontWeight: 390,
  color: "#292524"
})
const DetailItemLast = styled(Typography)({
  fontSize: "16px",
  fontWeight: 450,
  color: "#292524"
})


const MediumComponentColorBox = styled(Typography)(({ selectedColor }) => ({
  border: "1px solid #D6D3D1",
  width: "17px",
  height: "17px"
}));

const ThumbnailContainer = styled('div')({
  width: "50%",
  paddingRight: "46px",

  '@media (min-width: 0px)': {
    paddingRight: "46px",
  },

  '@media (min-width: 360px) and (max-width: 420px)': {
    paddingRight: "0px !important",
  },

  '@media (min-width: 500px) and (max-width: 700px)': {
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: "100%",
  },

  '@media (max-width: 820px)': {
    paddingRight: "0px !important",
    flexWrap: 'wrap',
    width: "100%"
  },

  '@media (min-width: 821px) and (max-width: 1199px)': {
    paddingRight: "20px",
    width: "100%",
  },

  '@media (min-width: 1200px)': {
    paddingRight: "46px",
  },

  '@media screen and (min-width: 1920px) and (min-height: 1200px)': {
    paddingRight: "60px",
  },

  '@media screen and (min-width: 1920px) and (max-height: 1080px)': {
    paddingRight: "50px",
  },

  '@media (min-width: 2560px)': {
    paddingRight: "80px",
  },
})

const RightSideDataComponent = styled('div')({
  marginTop: '10px',

  '@media (min-width: 768px) and (max-width: 600px)': {
    flexWrap: 'wrap',
  },
  '@media (max-width: 820px)': {
    flexWrap: 'wrap'
  },
});

const ProductImage = styled(CardMedia)({
  paddingTop: '100%',
  backgroundSize: 'contain',
  backgroundColor: '#f5f5f5',
});

const ZoomButton = styled('div')({
  position: "absolute",
  right: "16px",
  bottom:"16px",
  zIndex: 2,
  color: 'white',
  width: '33px',
  height: '33px',
  background: '#FFFFFF',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  "&.new_class":{
    bottom:"33px"
  },
  '@media  (max-width: 600px)': {
    right: "18px !important"
  },

});

const ZoomButtonAfterLogin = styled('div')({
  position: 'absolute',
  top: '80%',
  right: '17px',
  color: 'white',
  width: '33px',
  height: '33px',
  background: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

  '@media (max-width: 470px)': {
    top: '68%',
  },

  '@media (min-width: 471px) and (max-width: 500px)': {
    top: '60%',
  },

  '@media (min-width: 600px) and (max-width: 1199px)': {
    top: '80%',
    right: '19px',
  },

  '@media (min-width: 1025px) and (max-width: 1199px)': {
    top: '84%',
    right: '43px',
    width: '33px',
    height: '33px',
  },

  '@media (min-width: 1200px) and (max-width: 1300px)': {
    top: '45%',
    right: '18px',
  },

  '@media (min-width: 1300px) and (max-width: 1500px)': {
    top: '59%',
    right: '18px',
  },

  '@media screen and (min-width: 1501px) and (max-width: 1699px)': {
    top: '78%',
    right: '18px',
  },

  '@media screen and (min-width: 1700px) and (max-width: 1920px)': {
    top: '81%',
    right: '18px',
  },

  '@media screen and (min-width: 1921px) and (max-width: 2200px)': {
    top: '84%',
    right: '18px',
  }

});


const PriceTag = styled(Typography)({
  textDecoration: 'line-through',
  marginRight: '10px',
  color: "#292524",
  fontSize: "20px",
  fontWeight: 420

});

const DiscountedPrice = styled(Typography)({
  fontSize: "40px",
  fontWeight: 420,
  color: "#292524"
});

const BoxComponent = styled(Box)({
  padding: "5px 10px 14px 14px",
  borderRadius: "24px",
  opacity: "0px",
  border: "1px solid #528A6F",
  width: "73%",
  height: "14px",
  background: "#F4FFF3"
})

const SmallImagesContainer = styled(Grid)({
  marginTop: "10px",
  '@media (min-width: 320px) and (max-width: 470px)': {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
});

const PriceComponent = styled(Grid)({
  "&.price_with_token":{
    display: "flex",
    width: "343px",
    height: "51px",
    padding: "0px 6px",
    gap: "10px",
    border: "1px solid #D6D3D1",
    opacity: "0px",
    marginTop: "10px",
    '@media (max-width: 360px)': {
      width: "100% !important"
    },
  },
  "&.price-without-token":{
    display: "flex",
    width: "343px",
    height: "51px",
    padding: "0px 6px",
    gap: "10px",
    border: "1px solid #D6D3D1",
    opacity: "0px",
    marginTop: "10px",
    '@media (max-width: 360px)': {
      width: "100% !important"
    },
    '@media (min-width: 1900px) and (max-width: 2200px)': {
      width: "345px",
    },
    '@media (min-width: 2201px) and (max-width: 2700px)': {
      width: "347px",
    }
  }
 
})

const ProductDetailsSectionContainer = styled(Grid)({
  display: "flex",
  width: "60%",
  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "100%"
  },
  '@media (min-width: 471px) and (max-width: 600px)': {
    width: "100%"
  },
  '@media (min-width: 600px) and (max-width: 1199px)': {
    width: "100%"
  }
})

const DescriptionSectionContainer = styled(Grid)({
  display: "flex",
  width: "40%",
  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "100%"
  },
  '@media (min-width: 471px) and (max-width: 599px)': {
    width: "100%"
  },
  '@media (min-width: 600px) and (max-width: 1199px)': {
    width: "100%"
  }
})

const TotalWeightComponent = styled(Box)({
  display: "flex",
  alignItems: "center",
  '@media (min-width: 320px) and (max-width: 470px)': {
    marginTop: "11px"
  },
  '@media (min-width: 1199px) and (max-width: 1350px)': {
    marginTop: "11px"
  }
})

const TotalWeightComponentAfterLogin = styled(Box)({
  alignItems: "center",
  display: "flex",
  '@media (min-width: 320px) and (max-width: 470px)': {
    marginTop: "11px"
  },
  '@media (min-width: 1200px) and (max-width: 1500px)': {
    marginTop: "11px"
  }
})

const TotalWeightInnerComponentAfterLogin = styled(Typography)({
  fontSize: '16px',
  fontWeight: 450,
  color: '#162A26',
  paddingLeft: "16px",
  margin: "0px 16px 0px 0px",
  '@media (min-width: 320px) and (max-width: 470px)': {
    paddingLeft: "0px",
  },

  '@media (min-width: 1199px) and (max-width: 1500px)': {
    paddingLeft: "0px",
  }
})

const TotalWeightInnerComponentTypography = styled(Typography)({
  fontSize: '16px',
  fontWeight: 450,
  color: '#162A26',
  paddingLeft: "16px",
  margin: "0px 16px 0px 0px",
  '@media (min-width: 320px) and (max-width: 470px)': {
    paddingLeft: "0px",
  },
  '@media (min-width: 1200px) and (max-width: 1400px)': {
    paddingLeft: "0px",
    },
})

const ButtonComponentMain = styled(Button)({
  width: "149px !important",
  height: "32px",
  gap: "8px",
  opacity: "0px",
  boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 30px 0px, rgba(0, 0, 0, 0.0) 0px 2px 2px 0px, rgba(0, 0, 0, 0.1) 0px 2px 5px 0px",
  borderRadius: "8px",
  color: "#B7862B",
  fontSize: "14px",
  fontWeight: 420,
  background: "#FFFFFF",

  '@media (max-width: 360px)': {
    width: "155px !important"
  },
  '@media (max-width: 420px)': {
    width: "155px !important"
  },
})

const SmallImage = styled('img')({
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "85.44px",
  width: "85.44px",
  margin: "0px 21px 0px 0px",
  cursor: "pointer",
  '@media (min-width: 320px) and (max-width: 470px)': {
    display: "flex",
    justifyContent: "space-between",
    width: "30%",
    margin: "0px"
  },

});

const MeterWrapper = styled(Grid)({
  width: '342px',
  display: 'flex',
  alignItems: 'center',
  height: "50px",
  justifyContent: "space-between",

});


const StyledIconButtonOne = styled(IconButton)({
  '& svg': {
    fontSize: '1.5rem',
    color: '#333',
  },
});
const StyledIconButtonTwo = styled(IconButton)({
  '& svg': {
    fontSize: '1.5rem',
    color: '#333',
  },
});


const MeterInput = styled('div')({
  width: '50px',
  textAlign: 'center',
  fontSize: '21PX',
  fontWeight: '420',
  color: '#162A26',

});



const UnitBox = styled('div')({
  backgroundColor: '#f0fdf4',
  padding: '13px',
  fontSize: '14px',
  fontWeight: '420',
  color: '#162A26',
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  display: "flex",
  border: "1px solid #E0EEDF"
});

const MeterComponent = styled(Box)({
  width: "86px",
  height: "48px",
})

const UnitBoxComponent = styled(Box)({
  width: "67px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0FFF0',
  border: "1px solid #E0EEDF",
  borderColor: '#E0EEDF',
  height: '48px',
  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "82px",
  },
});

const UnitBoxComponentAfterLogin = styled(Box)({
  width: "67px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0FFF0',
  border: "1px solid #E0EEDF",
  borderColor: '#E0EEDF',
  height: '48px',
  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "82px",
  },
  '@media (min-width: 1199px) and (max-width: 1500px)': {
    width: "82px",
  }
})

const WeightBox = styled(Box)({
  width: "67px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  border: "1px solid #D6D3D1",
  height: '48px',
  borderRight: "none",
  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "159px",
    justifyContent: 'flex-start',
  },

  '@media (min-width: 1200px) and (max-width: 1400px)': {
    width:"174px",
    },
});

const WeightBoxAfterLogin = styled(Box)({
  width: "67px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  border: "1px solid #D6D3D1",
  height: '48px',
  borderRight: "none",
  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "159px",
    justifyContent: 'flex-start',
  },

  '@media (min-width: 1199px) and (max-width: 1500px)': {
    width: "159px",
    justifyContent: 'flex-start',
  }
})

const WeightInnerTypography = styled(Typography)({
  fontWeight: 420, fontSize: '16px', color: '#162A26',
  '@media (min-width: 320px) and (max-width: 470px)': {
    paddingLeft: "13px"
  },

  '@media (min-width: 1200px) and (max-width: 1300px)': {
    paddingLeft: "23px"
  }
})

const WeightInnerTypographyAfterLogin = styled(Typography)({
  fontWeight: 450, fontSize: '16px', color: '#162A26',
  '@media (min-width: 320px) and (max-width: 470px)': {
    paddingLeft: "13px"
  },

  '@media (min-width: 1199px) and (max-width: 1500px)': {
    paddingLeft: "13px"
  }
})


const QuantityText = styled('div')({
  fontSize: "16px",
  fontWeight: 450,
  marginLeft: "16px",
  '@media (max-width: 420px)': {
    marginLeft: "2px",
    marginTop: "10px"
  },
  '@media (max-width: 360px)': {
    width: "320px !important",
    marginLeft: "2px",
    marginTop: "10px"
  },
});

const ButtonContainerAfterLogin = styled(Box)({
  display: "flex",
  marginTop: "9px",
  " & .button_first_after_login": {
    height: "40px",
    width: "168px !important",
    borderRadius: "36px",
    opacity: "0px",
    gap: "8px",
    fontSize: "14px",
    fontWeight: 420,
    border: "1.5px solid #B7862B",
    color: "#B7862B",
    '@media (min-width: 320px) and (max-width: 470px)': {
      width: "100% !important",
      marginTop: "10px",
      marginLeft: "5px"
    },
    '@media (min-width: 480px) and (max-width: 700px)': {
      width: "f",
    },

  },
  " & .button_last_after_login": {
    width: "168px !important",
    fontSize: "14px",
    fontWeight: 420,
    background: "#1C1917",
    color: "#FAFAF9",
    height: "40px",
    gap: "8px",
    borderRadius: "36px",
    opacity: "0px",
    "&:hover": {
      background: "#1C1917",
    },
    '@media (min-width: 320px) and (max-width: 470px)': {
      width: "100% !important",
      marginTop: "10px",
      marginLeft: "0px"
    },
    '@media (min-width: 600px) and (max-width: 1199px)': {
      width: "412px !important"
    },

  },

  '@media (min-width: 320px) and (max-width: 470px)': {
    flexWrap: "wrap"
  },

  '@media (min-width: 500px) and (max-width: 700px)': {
    width: "526px",
  },
  '@media (min-width: 830px) and (max-width: 900px)': {
    width: "772px !important",
  },

})

const ButtonContainer = styled(Box)({
  marginTop: "43px",
  display: "flex",

  '@media (min-width: 320px) and (max-width: 470px)': {
    flexWrap: "wrap"
  },

  '@media (min-width: 500px) and (max-width: 700px)': {
    width: "631px",
  },
  " & .button_first": {
    width: "168px !important",
    height: "40px",
    gap: "8px",
    borderRadius: "36px",
    opacity: "0px",
    border: "1.5px solid #B7862B",
    color: "#B7862B",
    fontSize: "14px",
    fontWeight: 420,

    '@media (min-width: 320px) and (max-width: 470px)': {
      width: "100% !important",
      marginTop: "10px",
      marginLeft: "5px"
    },

  },
  " & .button_last": {
    width: "168px !important",
    height: "40px",
    gap: "8px",
    borderRadius: "36px",
    opacity: "0px",
    color: "#FAFAF9",
    fontSize: "14px",
    fontWeight: 420,
    background: "#1C1917",
    '@media (min-width: 600px) and (max-width: 1199px)': {
      width: "412px !important"
    },
    "&:hover": {
      background: "#1C1917",
    },
    '@media (min-width: 320px) and (max-width: 470px)': {
      width: "100% !important",
      marginTop: "10px",
      marginLeft: "0px"
    },
  },
});

const SimilarProductComponent = styled(Typography)({
  marginTop: "28px",
  fontSize: "24px",
  fontWeight: 420,
  color: "#292524",
  textTransform: "uppercase"
})

const StyledContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  width: "99%",
  flexDirection: 'column',
  overflow: 'hidden',
  background: "#F5F5F480",
  height: "95px",
  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "100% !important",
  },
  '@media (min-width: 800px) and (max-width: 820px)': {
    width: "98% !important",
  },

});

const MaterialLcaInnerContainer = styled('div')({
  width: "518px",
  marginTop: "3px",
  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "344px !important",
  },
  '@media (min-width: 471px) and (max-width: 600px)': {
    width: "504px !important",
  },
  '@media (min-width: 700px) and (max-width: 1199px)': {
    width: "700px !important",
  },

  '@media (min-width: 830px) and (max-width: 900px)': {
    width: "772px !important",
  },
})

const StyledContent = styled('div')({
  maxHeight: '100vh',
  overflowY: 'auto', display: "flex",
  justifyContent: "space-between",
  filter: "blur(4px)",
  transition: "filter 0.3s ease",

  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    borderRadius: '5px',
  },

  '@media (max-width: 768px)': {
    maxHeight: '80vh',
  },
});

const UnlockOverlay = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  '@media (max-width: 360px)': {
    width: "155px !important"
  },
  '@media (max-width: 420px)': {
    width: "155px !important"
  },
})

const ProductDetailContainer = styled(Grid)({
  '@media (min-width: 800px) and (max-width: 820px)': {
    flexDirection: "column !important"
  },
  '@media (min-width: 471px) and (max-width: 600px)': {
    flexDirection: "column !important"
  }


})

const DetailInnerContainer = styled(Grid)({
  '@media (max-width: 820px)': {
    flexWrap: "nowrap"
  },
  '@media (min-width: 320px) and (max-width: 470px)': {
    flexWrap: "wrap !important"
  },
  '@media (min-width: 471px) and (max-width: 600px)': {
    flexWrap: "wrap !important"
  }
})


const ProductDetailsSection = styled(Box)({
  padding: "0px"
})


const DetailItem = styled(Typography)({
  fontWeight: 450,
  fontSize: "16px",
  color: "#292524"
})


const DescriptionSection = styled(Box)({
  padding: "0px",
  '@media (min-width: 600px) and (max-width: 1199px)': {
    display: "flex",
    width: "100%"
  }
});

const CertificationsSection = styled(Box)({
  padding: "8px 1px",
  display: "flex",
  justifyContent: "space-between",
  width: "25%",

  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "100% !important",
  },
  '@media (min-width: 800px) and (max-width: 1199px)': {
    width: "47% !important",
  },

  '@media (min-width: 1200px) and (max-width: 1400px)': {
    width: "40% !important",
  },

  '@media (min-width: 1401px) and (max-width: 1500px)': {
    width: "40% !important",
  },
  '@media (min-width: 1501px) and (max-width: 1600px)': {
    width: "34% !important",
  },
  '@media (min-width: 1601px) and (max-width: 1700px)': {
    width: "30% !important",
  },
  '@media (min-width: 471px) and (max-width: 799px)': {
    width: "77% !important",
  }

})

const CertificationImage = styled('img')({
  height: "50px",
  width: "auto"
})

const TextBox = styled(Box)({
  background: '#F5F5F4',
  height: '305px',
'@media (min-width: 320px) and (max-width: 470px)': {
  padding: "0px 14px"
}
});
const TextInnerBox = styled('div')({
  padding:"16px",
  '@media (min-width: 320px) and (max-width: 470px)': {
  padding: "0px"
}
})

const DescriptionComponent = styled('p')({
  color:"#292524",
  '@media (min-width: 320px) and (max-width: 470px)': {
    fontSize: "12px",
    fontWeight: 390,
    lineHeight: "16.8px",
    letterSpacing: "0.03em",
    textAlign: "left"
  }


});

const DescriptionFirstComponent = styled('div')({
  '@media (min-width: 600px) and (max-width: 1199px)': {
    width: "60% !important"
  },

  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "100% !important"
  }

})

const LogisticalComponent = styled('div')({
  marginTop:"24px",
  '@media (min-width: 600px) and (max-width: 1199px)': {
    width: "49% !important"
  },
})

const DescrptionContentComponent = styled(Typography)({
  fontSize: "14px", fontWeight: 390, color: "#292524", marginTop: "7px",
  '@media (min-width: 600px) and (max-width: 1199px)': {
    width: "80% !important"
  },
})

const items: Item[] = new Array(5).fill({
  title: 'Sheer Lustre Faille',
  image: productImg,
  price: '£0.45 /kg',
  available: '150,000.00 kg'
});

const CardMainComponent = styled(Card)({
  boxShadow: "rgba(0, 0, 0, 0.9) 0px 0px 1px -1px, rgba(0, 0, 0, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px !important",
  "&.without_card_token":{
padding: "5px", cursor: "pointer",width:"235px",
'@media (min-width: 800px) and (max-width: 900px)': {
  width:"178px",
  },
  '@media (min-width: 901px) and (max-width: 1000px)': {
  width:"138px",
  },
  '@media (min-width:1001px) and (max-width: 1200px)': {
    width: "134px"
     },

  '@media (min-width: 1201px) and (max-width: 1400px)': {
  width:"199px",
  },
  },
  "&.with_card_token":{
    padding: "5px", cursor: "pointer",width:"178px",
    '@media (min-width: 850px) and (max-width: 1000px)': {
      width:"130px",
      },
    
    '@media (min-width: 1200px) and (max-width: 1400px)': {
      width:"155px",
      },
    '@media (min-width: 1901px) and (max-width: 2200px)': {
      width:"269px",
      },
      }
})


const CardMediaComponent = styled(CardMedia)({
  height: "140px"
});

const IconButtonComponent = styled(IconButton)({
  "& .css-i4bv87-MuiSvgIcon-root": {
    width: "18px",
    height: "16px"
  }
});

const IconButtonComponentOne = styled(IconButton)({
  position: 'absolute',
  top: '4px',
  right: '7px',
  color: 'white',

  '@media (max-width: 768px)': {
    right: "4px !important",
    top: "5px !important"
  },
  '@media (max-width: 1476px)': {
    right: "51px",
    top: "8px"
  },
  '@media (min-width: 800px) and (max-width: 1199px)': {
    right: "15px !important"
  },
  "& .css-i4bv87-MuiSvgIcon-root": {
    width: "28px",
    height: "25px"
  }
});

const CardContentComponent = styled("div")({
  padding: "4px",
  "& .css-46bh2p-MuiCardContent-root:last-child": {
    paddingBottom: "5px"
  }
});

const ContainerMain = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: "10px"
});



const ItemNew = styled('div')({
  textAlign: 'center',
});

const Value = styled(Typography)({
  fontSize: '14px',
  color: "#162A26",
  fontFamily: "brandon-grotesque, sans-serif",
  fontWeight: 420,
  textAlign: "left"
});

const PriceTypography = styled(Typography)({
  fontWeight: 400,
  fontFamily: "garamond-premier-pro !important",
  color: "#2D6A4D",
  fontSize: '11px',
  textAlign: "left"
});


const CardContentRowLast = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: "4px",
  marginBottom: "6px"
});

const MainContainer = styled('div')({
  display: "flex",
  '@media only screen and (max-width: 420px)': {
    overflowX: 'scroll',
    display: "flex",

  }
});

const GridContainerMain = styled(Grid)({
  display: 'flex',
  overflowX: 'auto' as 'auto',
  whiteSpace: 'nowrap' as 'nowrap',
  width: '100%',
  padding: '16px',
  boxSizing: 'border-box' as 'border-box',
})

const Wrapper = styled('div')({
  "&.without-token": {
    maxWidth: "1342px",
    background: "#FAFAF9",
    padding: "40px 152px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    '@media (min-width: 800px) and (max-width: 900px)': {
      padding: "0px !important",
      maxWidth: "680px",
    },
    '@media (min-width: 901px) and (max-width: 1099px)': {
      padding: "0px !important",
      maxWidth: "800px",
    },
    '@media (min-width: 1100px) and (max-width: 1200px)': {
      padding: "0px !important",
      maxWidth: "800px",
    },
    '@media (min-width: 1200px) and (max-width: 1400px)': {
      padding: "40px 127px",
      maxWidth: "903px",
    },

  
    '@media (min-width: 320px) and (max-width: 460px)': {
      padding: "0px 12px !important",
      maxWidth: "320px",
      height: "413px",
    },

    '@media (min-width: 461px) and (max-width: 780px)': {
      padding: "0px 12px !important",
      maxWidth: "521px",
      height: "413px",
    },
    '@media (min-width: 781px) and (max-width: 799px)': {
      padding: "0px 12px !important",
      maxWidth: "521px",
      height: "413px",
    },
  },
  "&.with-token": {
    maxWidth: "955px",
    background: "#FAFAF9",
    padding: "40px 75px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    '@media (min-width: 800px) and (max-width: 900px)': {
      padding: "0px !important",
      maxWidth: "680px",
    },
    '@media (min-width: 901px) and (max-width: 1400px)': {
      maxWidth: "880px",
      padding: "40px 43px",
    },
    '@media (min-width: 1500px) and (max-width: 1599px)': {
     maxWidth: "1095px"
       },

       '@media (min-width: 1600px) and (max-width: 1900px)': {
     maxWidth: "1275px"
       },
       '@media (min-width: 1901px) and (max-width: 2200px)': {
      maxWidth: "1561px"
       },
    '@media (min-width: 320px) and (max-width: 470px)': {
      padding: "0px 32px !important",
      maxWidth: "320px",
      height: "413px",
    },
    '@media (min-width: 471px) and (max-width: 780px)': {
      padding: "0px 32px !important",
      maxWidth: "1000px",
      height: "413px",
    },
  }
});

const SimilarProductWrapper = styled('div')({

  "&.without-similar-token":{
     maxWidth:"1342px",
  padding: "0px 1px 0px 16px",
  '@media (min-width:900px) and (max-width: 1000px)': {
    padding: "0px 0px 0px 0px"
     },
  '@media (min-width:1001px) and (max-width: 1100px)': {
    padding: "0px 0px 0px 0px"
     },

     '@media (min-width:1101px) and (max-width: 1200px)': {
      padding: "0px 0px 0px 0px"
       },
     '@media (min-width: 1501px) and (max-width: 1600px)': {
    padding: "0px 0px 0px 94px"
     },
     '@media (min-width: 1601px) and (max-width: 1900px)': {
    padding: "0px 0px 0px 159px"
     },
     '@media (min-width: 1901px) and (max-width: 2200px)': {
       padding: "0px 0px 0px 143px"
       },
  },
  "&.with-similar-token":{
    maxWidth:"1092px",
    padding: "0px 1px 0px 16px",
    '@media (min-width: 900px) and (max-width: 1400px)': {
      maxWidth:"944px",
       },
       '@media (min-width: 1500px) and (max-width: 1900px)': {
      padding: "0px 0px 0px 105px"
       },
       '@media (min-width: 1901px) and (max-width: 2200px)': {
         padding: "0px 0px 0px 100px",
         maxWidth:"1528px",
         },
  }
 
})

const InspirationComponent = styled(Typography)({
  textTransform: "uppercase",
  fontSize: "20px",
  fontWeight: 300,
  fontFamily: "garamond-premier-pro !important",
  color:"#292524"
});

const InspirationContainer = styled(Grid)({
  "&.without_token":{
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    padding: "20px 0",
     '@media (min-width: 500px) and (max-width: 799px)': {
      flexFlow: "nowrap",
    gap: "0px",
    },
    '@media (min-width: 800px) and (max-width: 1199px)': {
      flexFlow: "nowrap",
    gap: "0px",
    },
    '@media (min-width: 1200px) and (max-width: 1299px)': {
      width:"100%",
    flexFlow: "nowrap"
      },
    '@media (min-width: 1300px) and (max-width: 1400px)': {
     width:"100%",
    flexFlow: "nowrap"
    },

    '@media (min-width: 800px) and (max-width: 900px)': {
    flexFlow: "nowrap",
    gap: "0px",
     },

     '@media (min-width: 900px) and (max-width: 1499px)': {
    width:"100%",
    flexFlow: "nowrap"
     },
  
     '@media (min-width: 1500px) and (max-width: 1700px)': {
    width:"87%"
     },
     '@media (min-width: 1701px) and (max-width: 1900px)': {
      width:"78%"
       },
     '@media (min-width: 1901px) and (max-width: 2200px)': {
      width:"79%"
       },
     '@media (min-width: 320px) and (max-width: 470px)': {
      flexFlow: "nowrap",
    gap: "0px",
     },
     '@media (min-width: 461px) and (max-width: 780px)': {
      flexFlow: "nowrap",
    gap: "0px",
     },
  },
  "&.token":{
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    padding: "20px 0",
    '@media (min-width: 800px) and (max-width: 900px)': {
    flexFlow: "nowrap",
    gap: "0px",
     },
     '@media (min-width: 900px) and (max-width: 1499px)': {
    width:"100%",
    flexFlow: "nowrap"
     },
  
     '@media (min-width: 1500px) and (max-width: 1699px)': {
    width:"93%"
     },
     '@media (min-width: 1700px) and (max-width: 1900px)': {
      width:"83%"
       },
     '@media (min-width: 1901px) and (max-width: 2200px)': {
      width:"69%"
       },
     '@media (min-width: 320px) and (max-width: 470px)': {
      flexFlow: "nowrap",
    gap: "0px",
     },
  }

 
});

const InspiratinMidText = styled("div")({
  width: "381px",
  height: "305px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "left",
  backgroundColor: "#f8f8f8",
  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "195px"
   },
   '@media (min-width: 471px) and (max-width: 600px)': {
    width: "207px"
    },
   '@media (min-width: 601px) and (max-width: 799px)': {
   width: "207px"
   },
  '@media (min-width: 800px) and (max-width: 900px)': {
    width: "237px"
   },

});

const ImageContainerOne = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "305px",
  backgroundColor: "#e0e0e0",
  overflow: "hidden",
  '@media (min-width: 320px) and (max-width: 470px)': {
    width: "40.5px",
  },
  '@media (min-width: 471px) and (max-width: 500px)': {
    width: "71px",
  },
  '@media (min-width: 501px) and (max-width: 599px)': {
    width: "87px",
  },
  '@media (min-width: 601px) and (max-width: 700px)': {
    width: "96px",
  },
  '@media (min-width: 701px) and (max-width: 799px)': {
    width: "143px",
  },
  '@media (min-width: 800px) and (max-width: 1199px)': {
    width: "221px",
  },
});


// Customizable Area End
