import React from "react";
// Customizable Area Start
import {
  createTheme,
  Theme,
} from "@mui/material/styles";
import { Button, Container, Grid, Box, Card, CardMedia, CardContent, Tooltip, Typography, IconButton, Divider } from "@mui/material";
import { styled } from "@mui/styles";
import { FavoriteBorder as FavoriteBorderIcon } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { CatalogueBanner, EqualImage, TilesImage, LeftArrow,MapImage, MenuItemImage, RightArrow, SearchImage, GridImage, productImg, SortIcon } from './assets';
// Customizable Area End

// Customizable Area Start
import CatalogueController, { CatalogueItem, Props, configJSON } from "./CatalogueController";
import { NavigationWrapper } from "../../../blocks/navigationmenu/src/NavigationWrapper.web";
import Loader from "../../../components/src/Loader.web";
import CommonModalComponent from "../../../components/src/CommonModalComponent";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderGridBox = () => {
    return (
      <>
        <Loader loading={this.state.loading} />
        <GridBoxComponent  container spacing={3}>
          {this.state.catalogueDataMain.map((item: CatalogueItem, index: number) => (
            <React.Fragment key={index}>
              {index === 14 && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      margin: "4px 6px 0px 17px"
                    }}
                  >
                    <PillowCaseComponentBox container spacing={2} >
                      <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} >
                        <Box >
                          <PillowCaseTitleComponent gutterBottom>
                            {configJSON.pillowCaseTitle}
                          </PillowCaseTitleComponent>
                          <PillowCaseDescriptionComponent variant="body1" style={{ color: "#162A26", fontSize: "15px", fontFamily: "brandon-grotesque, sans-serif" }} gutterBottom>
                            {configJSON.pillowCaseDescription}
                          </PillowCaseDescriptionComponent>
                          <Button style={{ fontFamily: "brandon-grotesque, sans-serif", backgroundColor: "#FFF", marginTop: "19px", width: "135px ", borderRadius: "18px", color: "#B7862B", borderColor: "#B7862B", borderWidth: 1, borderStyle: "solid" }}>LEARN MORE</Button>
                        </Box>
                      </Grid>
                    </PillowCaseComponentBox>
                  </Box>
                </Grid>
              )}

              {this.state.activeGridImage && (
                 <Grid item xs={12} sm={4} md={3} lg={12 / 6}>
                  <CardMainComponent style={{ padding: "5px", cursor: "pointer" }}>
                    <div style={{ position: 'relative' }} data-test-id="grid-navigate-id" onClick={() => this.handleProductDescription(item)}>
                      <CardMediaComponent
                        image={item.attributes?.pictures[0]?.url ?? productImg}
                      />
                      <IconButtonComponent data-test-id="login-signup-test-id" onClick={(event) => {
                        event.stopPropagation()
                        this.handleLoginSignup()
                      }} style={{ position: 'absolute', top: 1, right: 0, zIndex: 1, color: 'white' }}>
                        <FavoriteBorderIcon />
                      </IconButtonComponent>
                    </div>
                    <CardContentComponent>
            <Tooltip title={item.attributes.display_name}>
  <Typography style={{ 
      fontWeight: 420, 
      fontSize: '16px', 
      marginTop: "10px", 
      color: "#1C1917",
      overflow: "hidden", 
      textOverflow: "ellipsis", 
      display: "inline-block", 
      width: "133px"  
    }}>
    {item?.attributes?.display_name && item.attributes.display_name.length > 40
  ? `${item.attributes.display_name.substring(0, 40)}...`
  : item.attributes?.display_name}
  </Typography>
</Tooltip>
<div style={{display:"flex", flexDirection:"column"}}>
   <ContainerMain>
           <ItemNew>
        <PriceTypography>PRICE</PriceTypography>
        <Value>
        £0.45 /kg
          </Value>
      </ItemNew>
      <ItemNew>
        <PriceTypography>REQUESTED</PriceTypography>
        <Value>
        150,000 kg
          </Value>
      </ItemNew>     
    </ContainerMain>
    <CardContentRowLast>
                <div style={{ height: "14px", width: "14px", backgroundColor: "#D84539" }} />
              </CardContentRowLast>
</div>
     
            </CardContentComponent>
                  </CardMainComponent>
                </Grid>
              )}

              {this.state.activeTiles && (
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <CardComponentTiles>
                    <TilesMainDiv>
                      <div data-test-id="tiles-navigate-id" onClick={() => this.handleProductDescriptionFirst(item)} style={{ position: 'relative', padding: "2px" }}>
                        <CardMediaImageComponent
                          image={item.attributes.pictures[0]?.url ?? productImg}
                        />
                        <IconButtonComponent
                        data-test-id="login-signup-tiles-test-id" onClick={(event) => {
                          event.stopPropagation()
                          this.handleLoginSignupTiles()
                        }}
                          sx={{ position: 'absolute', top: 4, right: 4, color: '#FFF', }}
                          size="small"
                        >
                          <FavoriteBorderIcon style={{ width: "18px", height: "16px" }} />
                        </IconButtonComponent>
                      </div>
                      <div style={{ width: "100%" }}>
                        <CardContentTilesComponent style={{ display: "flex" }}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TileTitleComponent>
                              {item.attributes.display_name}
                            </TileTitleComponent>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={6} md={6}>
                            <PriceContentComponent>
                              <div>
                                <ColoredBoxComponent />
                              </div>
                              <PriceMainComponent>
                                <div>
                                  <TilePriceComponent>
                                    PRICE
                                  </TilePriceComponent>
                                  <TilePriceValueComponent>
                                    £29.35/m
                                  </TilePriceValueComponent>
                                </div>
                                <div>
                                  <TilePriceComponent>
                                  REQUESTED
                                  </TilePriceComponent>
                                  <TilePriceValueComponent>
                                    150 m
                                  </TilePriceValueComponent>
                                </div>
                              </PriceMainComponent>
                            </PriceContentComponent>
                          </Grid>
                        </CardContentTilesComponent>
                      </div>
                    </TilesMainDiv>
                  </CardComponentTiles>
                </Grid>
              )}
            </React.Fragment>
          ))}
        </GridBoxComponent>
      </>
    );
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <NavigationWrapper navigation={this.props.navigation} id="">
        <ContainerRoot className={this.state.token ? "container-with-token" : "container-without-token"}>
          <MarketCircularGridComponent container spacing={2} alignItems="center">
            <ResponsiveGridCricular item xs={12} md={8} sm={12}>
              <Title>{configJSON.circularMarketPLaceTitle}</Title>
              <Subtitle>{configJSON.circularMarketPLaceDescription}</Subtitle>
            </ResponsiveGridCricular>
            <ResponsiveGrid style={{marginTop:"30px"}} item xs={12} md={4}>
                            <ResponsiveInnerGrid container spacing={1}>
                                <ResponsiveInnerDiv>
                                    <Grid item>
                                        <SearcgTextComponent style={{ fontWeight: "450",paddingRight:"5px" }}>Search</SearcgTextComponent>
                                    </Grid>
                                    <Grid item>
                                        <SearchImageComponent src={SearchImage} style={{paddingRight:"30px"}}/>
                                    </Grid>
                                    <Grid item>
                                        <ImageComponentSort style={{paddingRight:"19px"}} src={SortIcon} />
                                    </Grid>
                                </ResponsiveInnerDiv>
                                
                                <ResponsiveInnerDiv>
                                <DividerComponent orientation="vertical" flexItem />
                                    <Grid item>
                                        <IconButton style={{cursor:"pointer"}} data-test-id="tilesToggleId" onClick={this.handleActiveTiles}>
                                        {this.state.activeTiles ? (
                                           <ImageComponentTiles src={TilesImage} />
                                        ):(
                                          <ImageComponentTiles src={EqualImage} />
                                        )}
                                            
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton data-test-id="gridToggleId" onClick={this.handleActiveGrid}>
                                        {this.state.activeGridImage ? (
                                           <ImageComponent src={MenuItemImage} />
                                        ):(
                                          <ImageComponent src={GridImage} />
                                        )}
                                           
                                        </IconButton>
                                        <IconButton data-test-id="gridToggleId">
                                           <ImageComponent src={MapImage} />
                                      
                                        </IconButton>
                                    </Grid>

                                </ResponsiveInnerDiv>

                            </ResponsiveInnerGrid>
                            
                        </ResponsiveGrid>
          </MarketCircularGridComponent>
          {this.renderGridBox()}
          {!this.state.loading &&
          <ContainerBoxCoponent>
            <StyledPagination
              count={10}
              siblingCount={0}
              renderItem={(item) => (
                <>
                  <StyledPaginationItem
                    slots={{
                      previous: () => <img src={LeftArrow} alt="Previous" />,
                      next: () => <img src={RightArrow} alt="Next" />
                    }}
                    {...item}
                  />
                </>

              )}
            />
          </ContainerBoxCoponent>
          }
          <CommonModalComponent
          data-test-id="modal-click-id"
            open={this.state.isModalOpen}
            handleClose={this.handleCloseModal}
            title="Access ProcureCircular"
            content={configJSON.ModalContent}
            primaryButtonLabel="LOG IN"
            secondaryButtonLabel="SIGN UP"
            primaryAction={this.handleNavigateLogin}
            secondaryAction={this.handleNavigateSignup}
          />
        </ContainerRoot>

      
      </NavigationWrapper>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const PillowCaseDescriptionComponent = styled(Typography)({
  margin: "0px 15px 0.35em 0px"
})

const ContainerMain = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop:"10px"
});


const TilesMainDiv = styled('div')({
display: "flex", height: "82px", cursor: "pointer",
})

const ItemNew = styled('div')({
  textAlign: 'center',
});

const Value = styled(Typography)({
  fontSize: '14px', 
  color: "#1C1917",
   fontWeight: 420,
   textAlign:"left"
});

const CardComponentTiles = styled(Card)({
  boxShadow: "rgba(0, 0, 0, 0.9) 0px 0px 1px -1px, rgba(0, 0, 0, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px !important"
})

const CardMainComponent = styled(Card)({
  boxShadow: "rgba(0, 0, 0, 0.9) 0px 0px 1px -1px, rgba(0, 0, 0, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px !important"
})


const PillowCaseTitleComponent = styled(Typography)({
  fontSize: "24px",
  fontFamily: "garamond-premier-pro !important",
  color: "#162A26"
})

const CardContentTilesComponent = styled(CardContent)({
  '@media (max-width: 420px)': {
    flexDirection: "column",
  },
  '@media (max-width: 360px)': {
    flexDirection: "column",
  },
  '@media (max-width: 430px)': {
    flexDirection: "column",
  },
})

const PriceContentComponent = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  '@media (max-width: 420px)': {
    alignItems: "center",
  },
  '@media (max-width: 360px)': {
    alignItems: "center",
  },
  '@media (max-width: 430px)': {
    alignItems: "center",
  },
})

const TilePriceValueComponent = styled(Typography)({
  fontWeight: 420,
  color: '#1C1917',
  fontSize: "14px"
})

const ColoredBoxComponent = styled('div')({
  height: "14px", width: "14px", backgroundColor: "#F6D7C9"
})

const PriceMainComponent = styled('div')({
  display: "flex",
  width: "74%",
  justifyContent: "space-between"
})

const TilePriceComponent = styled(Typography)({
  fontSize: "11px",
  fontFamily: "garamond-premier-pro !important",
  textTransform: "uppercase",
  fontWeight: 400,
  color: "#528A6F",
   textAlign: "left"
})

const TileTitleComponent = styled(Typography)({
  fontWeight: 420,
  fontSize: "16px",
  fontFamily: "brandon-grotesque, sans-serif",
  color: '#162A26'
})

const CardMediaImageComponent = styled(CardMedia)({
  height: "78px",
  width: "92px",
})
const IconButtonComponent = styled(IconButton)({
  "& .css-i4bv87-MuiSvgIcon-root": {
    width: "18px",
    height: "16px"
  }
});

const ContainerRoot = styled(Container)(({ theme }) => ({
  flexGrow: 1,


  // Styles for when there's no token
  '&.container-without-token': {
    paddingLeft: "128px",
    paddingRight: "128px",
    marginTop: "84px",

    '@media (max-width: 1200px)': {
      paddingLeft: "28px",
      paddingRight: "28px",
    },
    
    '@media (max-width: 390px)': {
      paddingLeft: "32px",
      paddingRight: "32px"
    },



    '@media (min-width: 400px) and (max-width:420px)': {
    paddingLeft: "35px",
      paddingRight: "27px"
  },
  },

  '&.container-with-token': {
    paddingLeft: "52px",
    paddingRight: "52px",
    marginTop: "42px",

    '@media (max-width: 1200px)': {
      paddingLeft: "28px",
      paddingRight: "28px",
    },
    
    '@media (max-width: 390px)': {
      paddingLeft: "31px",
      paddingRight: "33px"
    },

    '@media (max-width: 760px)': {
      paddingLeft: "61px",
      paddingRight: "57px"
    },
  },

  '@media (min-width: 1200px)': {
    maxWidth: '1600px',
  },

 '@media (min-width: 800px) and (max-width: 1200px)': {
    paddingLeft: "28px",
    paddingRight: "28px",
  },

  // Small screens (e.g. mobile devices)
  '@media (max-width: 800px)': {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
}));


const GridBoxComponent = styled(Grid)({
  marginTop:"47px",
  '@media (min-width: 800px) and (max-width: 1200px)': {
    marginTop:"0px",
   },

   '@media (min-width: 320px) and (max-width: 740px)': {
    marginTop:"0px",
   }
  })

const MarketCircularGridComponent = styled(Grid)({
  '@media (max-width: 960px)': {
    marginTop: "6px"

  },
  '@media (max-width: 820px)': {
    marginTop: "6px !important"
  },
  '@media (max-width: 600px)': {
    marginTop: "6px !important"
  },
})

const CardMediaComponent = styled(CardMedia)({
  height: "140px"
});

const PriceTypography = styled(Typography)({
  fontWeight: 400,
  color: "#528A6F",
   fontSize: '11px' ,
   textAlign: "left",
   textTransform:"uppercase",
   fontFamily: "garamond-premier-pro !important",
});


const CardContentComponent = styled("div")({
  padding: "4px",
  "& .css-46bh2p-MuiCardContent-root:last-child": {
    paddingBottom: "5px"
  }
});

const CardContentRowLast = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: "4px",
  marginBottom: "6px"
});


const ContainerBoxCoponent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '10px 0',
  backgroundColor: "#F1F5F9",
  marginTop: "17px"
});

const StyledPagination = styled(Pagination)({
  width: "100%",
  '& .MuiPagination-ul': {
    position: 'relative',
    justifyContent: 'center',
    width: "100%"
  },
  '& .MuiPagination-ul li:first-child': {
    position: 'absolute',
    left: 0,
  },
  '& .MuiPagination-ul li:first-child button::before': {
    content: '"Previous"',
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    color: "#045544",
    fontFamily: "brandon-grotesque, sans-serif",
  },
  '& .MuiPagination-ul li:last-child': {
    position: 'absolute',
    right: 0,
  },
  '& .MuiPagination-ul li:last-child button::before': {
    content: '"Next"',
    position: 'absolute',
    right: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    color: "#045544",
    fontFamily: "brandon-grotesque, sans-serif",
  },
  '& .MuiPagination-ul li:first-child button:hover, & .MuiPagination-ul li:last-child button:hover': {
    backgroundColor: 'unset',
  },
  '@media (max-width: 420px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    }, '& .MuiPagination-ul li:first-child button::before': {
      content: '""',
    }
  },
  '@media (max-width: 375px)': {
    '& .MuiPagination-ul li:first-child button::before': {
      left: "-10px",
      
    },
    '& .MuiPagination-ul': {
      padding: "0px 3px"
    }
  },

 
  '@media (max-width: 430px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    }, '& .MuiPagination-ul li:first-child button::before': {
      content: '""',
    }
  },
  '@media (max-width: 360px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    }, '& .MuiPagination-ul li:first-child button::before': {
      content: '""',
    }, '& .css-whh4o0-MuiButtonBase-root-MuiPaginationItem-root': {
      margin: "0px 0px",
      padding:"0px 0px"
    },
    ' & .MuiPagination-ul li:last-child': {
      right: "0",
      position: "absolute",
      left: "91%",
    },
    '& .MuiPagination-ul': {
      padding: "0px 2px"
    }
  },

  '@media (max-width: 390px)': {
    '& .css-whh4o0-MuiButtonBase-root-MuiPaginationItem-root': {
      margin: "0px 0px",
      padding:"0px 0px",
      minWidth:"29px"
    },
   
  },
  '@media (max-width: 320px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    }, '& .MuiPagination-ul li:first-child button::before': {
      content: '""',
    }
  },
});

const StyledPaginationItem = styled(PaginationItem)({
  '&.MuiPaginationItem-root.Mui-selected': {
    backgroundColor: '#045544',
    color: '#fff',
    width: "100%"
  },
  '&.MuiPaginationItem-root': {
    borderRadius: '4px',
    color: "#045544"

  },
});

const PillowCaseComponentBox = styled(Grid)({
  justifyContent: "flex-end",
  alignItems: "center",
  background: `url(${CatalogueBanner})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: "349px"
})

const Title = styled('h1')({
  fontSize: '24px',
  color: '#2e7d32',
  margin: 0,
  fontWeight: 450,
  alignItems: "center",
  fontFamily: "brandon-grotesque, sans-serif",
  '@media (max-width: 960px)': {
    width: "100%"
  },
  '@media (max-width: 840px)': {
    width: "100%"
  },
  '@media (max-width: 420px)': {
    width: "100%"
  },
});

const Subtitle = styled('p')({
  fontSize: '15.098px',
  color: '#162A26',
  marginTop:"24px",
  fontWeight: 420,
  fontFamily: "brandon-grotesque, sans-serif",

});

const ResponsiveGrid = styled(Grid)({
  marginTop: "10px",
  '@media (max-width: 960px)': {
    textAlign: 'left',
    marginTop: "10px"
  },
  '@media (max-width: 600px)': {
    textAlign: 'left',
    marginTop: "10px",
    "& .css-ofrcno-MuiGrid-root > .MuiGrid-item": {
      marginTop: "15px"
    }
  },
});

const ResponsiveGridCricular = styled(Grid)({
  height: "56px",
  '@media (max-width: 960px)': {
    textAlign: 'left',

  },
  '@media (max-width: 600px)': {
    textAlign: 'left',
  },
})


const ResponsiveInnerGrid = styled(Grid)({
  justifyContent: "flex-end",
  alignItems: "center",
  '@media (max-width: 960px)': {
      textAlign: 'center',
      justifyContent: 'flex-end',
      marginTop:"10px"
  },
'@media (min-width: 800px) and (max-width: 1200px)': {
  textAlign: 'center',
  justifyContent: 'flex-end',
  marginTop:"50px"
 },

 '@media (min-width: 320px) and (max-width: 740px)': {
  textAlign: 'center',
    justifyContent: 'flex-end',
    marginTop:"50px"
 }
})

const ResponsiveInnerDiv = styled(Grid)({
  display: "flex",
  alignItems: "center"
})

const ImageComponent = styled("img")({
  width:"22px",
  height:"22px",
})
const ImageComponentTiles = styled("img")({
  width:"17.3px",
  height:"17.3px",
})

const ImageComponentSort = styled("img")({
  width:"35px",
  height:"32px",
})

const DividerComponent = styled(Divider)({
  '@media (max-width: 840px)': {
    display: "none"
  },
  '@media (max-width: 420px)': {
    display: "none"
  },
})

const SearchImageComponent = styled("img")({
  '@media (max-width: 840px)': {
    paddingRight: "10px"
  },
  '@media (max-width: 420px)': {
    paddingRight: "10px"
  },
})

const SearcgTextComponent = styled(Typography)({
  paddingLeft: "10px",
  fontFamily: "brandon-grotesque, sans-serif",
  color: "#162A26"
})
// Customizable Area End
